<template>
  <div class="mb-7">
    <v-card-text class="pa-5 border-bottom">
      <h3
        class="subtitle blue-grey--text text--darken-2 font-weight-regular mt-3"
      >
        Informações dos terminais do contrato com o fornecedor
      </h3>
    </v-card-text>

    <v-card-text class="pa-5">
      <v-form>
        <div class="mt-4">
          <v-data-table
            :headers="headers"
            :items="terminais"
            sort-by="Descricao"
            class="border"
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title>Terminais registrados</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="!showForm"
                  color="primary"
                  dark
                  class="mb-2"
                  @click="showForm = !showForm"
                  >Novo Terminal</v-btn
                >
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="editarTerminal(item)"
                >mdi-pencil</v-icon
              >
              <v-icon small @click="confirmarExcluirTerminal(item)"
                >mdi-delete</v-icon
              >
            </template>
            <template v-slot:no-data>
              <span>Sem terminais adicionados</span>
            </template>
          </v-data-table>
          <v-form v-if="showForm">
            <v-row>
              <v-toolbar flat color="white">
                <v-toolbar-title>Novo terminal</v-toolbar-title>
              </v-toolbar>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" lg="12" class="pt-0">
                <v-text-field
                  label="Nome"
                  required
                  v-model="terminal.descricao"
                ></v-text-field>
              </v-col>
            </v-row>
            <div class="pa-5 border-top text-right">
              <v-btn
                color="info"
                class="mr-2 text-capitalize"
                @click="salvarTerminal"
                >{{ textoBotaoSalvar }}</v-btn
              >
              <v-btn color="black" class="text-capitalize" dark @click="reset"
                >Cancelar</v-btn
              >
            </div>
          </v-form>
        </div>
      </v-form>
      <confirm-dialog @confirm="deleteTerminal" />
    </v-card-text>
  </div>
</template>

<style lang="scss">
.error__delete_title {
  color: #ff5252;
}
.error__delete_text {
  color: #545454;
}
</style>

<script>
import { mapActions } from 'vuex'
import ConfirmDialog from '@/components/template/ConfirmDialog'

// import CardMultitarefas from '../../../CardMultitarefas/CardMultitarefas.vue'

export default {
  name: 'DadosTerminal',
  components: {
    ConfirmDialog,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Nome',
          align: 'start',
          value: 'descricao',
        },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      terminais: {},
      showForm: false,
      terminal: {},
      edicao: false,
      indiceEdicao: -1,
    }
  },

  computed: {
    textoBotaoSalvar() {
      return this.edicao ? 'Alterar terminal' : 'Adicionar terminal'
    },
  },

  async created() {
    this.terminais = this.value.terminais
  },

  methods: {
    ...mapActions('fornecedores', [
      'adicionarTerminal',
      'atualizarTerminal',
      'excluirTerminal',
    ]),
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),

    reset() {
      this.terminal = {}
      this.showForm = false
      this.edicao = false
    },

    async salvarTerminal() {
      if (!this.value.public_id) return
      this.terminal.fornecedor = this.value.public_id
      if (this.edicao) {
        try {
          const novoTerminal = await this.atualizarTerminal(this.terminal)
          this.successMessage('Terminal atualizado com sucesso')
          this.terminais.splice(this.indiceEdicao, 1, novoTerminal)
        } catch (error) {
          this.errorMessage('Erro ao atualizar terminal: ' + error)
        }
      } else {
        try {
          const novoTerminal = await this.adicionarTerminal(this.terminal)
          this.successMessage('Terminal adicionado com sucesso')
          this.terminais.push(novoTerminal)
        } catch (error) {
          this.errorMessage('Erro ao adicionar terminal: ' + error)
        }
      }
    },

    editarTerminal(terminal) {
      Object.assign(this.terminal, terminal)
      this.indiceEdicao = this.terminais.indexOf(terminal)
      this.showForm = true
      this.edicao = true
    },

    confirmarExcluirTerminal(terminal) {
      this.terminal = terminal
      this.setDialogMessage({
        title: 'Confirmar exclusão?',
        message: 'Deseja mesmo excluir esse terminal?',
      })
    },

    async deleteTerminal() {
      const id = this.terminal.id
      try {
        await this.excluirTerminal({
          id,
          fornecedor: this.value.public_id,
        })
        const index = this.terminais
          .map(function (x) {
            return x.id
          })
          .indexOf(id)
        this.terminais.splice(index, 1)
        this.successMessage('Terminal excluído com sucesso')
      } catch (error) {
        this.errorMessage('Erro ao excluir terminal: ' + error)
      }
    },
  },
}
</script>