<template>
  <v-card elevation="2" class="card__multitarefas">
    <v-card-title class="py-0 card__title">
      <v-card-title>{{ nome }}</v-card-title>
    </v-card-title>
    <v-text-field
      class="mx-4 mt-5"
      :class="{ errorInput: formError }"
      :label="placeholder"
      outlined
      :disabled="!editable || !hasSelecionado"
      v-model="novoItem.descricao"
      @keyup="changeInput"
    >
      <template v-slot:append>
        <v-icon v-if="isEditable" class="pencil" x-large @click="edit">
          mdi-pencil
        </v-icon>
        <v-icon v-else x-large @click="save"> mdi-plus </v-icon>
      </template>
    </v-text-field>
    <v-card-text v-for="(item, index) in items" :key="index">
      <v-card
        class="d-flex line-data"
        elevation="2"
        :class="{ selected: isSelected === item.id }"
      >
        <div class="d-flex align-center pl-4" :class="widthDescription">
          {{ item.descricao }}
        </div>
        <div
          class="d-flex justify-center line-data__icons"
          @click="deleteItem(item)"
        >
          <v-icon> mdi-minus </v-icon>
        </div>

        <div
          v-if="hasEdit && item.hasOwnProperty('codigo_interno')"
          class="d-flex justify-center line-data__icons"
          @click="$emit('configItem', item)"
        >
          <v-icon> mdi-cog </v-icon>
        </div>
        <div
          v-else-if="hasEdit"
          class="d-flex justify-center line-data__icons"
          @click="$emit('configSubProdutoItem', item)"
        >
          <v-icon> mdi-cog </v-icon>
        </div>

        <div
          v-if="item.hasOwnProperty('codigo_interno')"
          class="d-flex justify-center line-data__icons"
          title="Adicionar Código Interno"
          @click="$emit('addCodigoInterno', item)"
        >
          <v-icon> mdi-barcode </v-icon>
        </div>
        <div
          v-if="hasNext"
          class="d-flex justify-center line-data__icons"
          @click="next(item)"
        >
          <v-icon> mdi-chevron-right </v-icon>
        </div>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'CardMultitarefas',
  props: {
    nome: String,
    placeholder: String,
    tipo: String,
    items: Array,
    editable: Boolean,
    hasNext: Boolean,
    hasEdit: Boolean,
    hasChangeInput: Boolean,
    hasSelecionado: Boolean,
    editItem: Object,
  },
  data() {
    return {
      novoItem: {
        descricao: '',
      },
      defaultNovoItem: {
        descricao: '',
      },
      formError: false,
      isEditable: false,
      isSelected: false,
    }
  },
  computed: {
    form() {
      return {
        descricao: this.novoItem.descricao,
      }
    },

    widthDescription() {
      if (this.hasEdit && this.hasNext) {
        return 'line-data__description-55'
      } else {
        return 'line-data__description-70'
      }
    },
  },
  watch: {
    editItem(newValue) {
      if (newValue) {
        Object.assign(this.novoItem, newValue)
      }
      if (newValue.isNotEdit) {
        this.isEditable = false
      }
    },
  },
  methods: {
    next(item) {
      this.isSelected = item.id
      this.isEditable = false
      this.$emit('next', item, this.tipo)
    },
    prepareEdit(item) {
      this.isSelected = item.id
      this.isEditable = item.id
      this.$emit('prepareEdit', item)
    },
    edit() {
      if (!this.isEditable) return

      this.$emit('edit', { ...this.novoItem })
      Object.assign(this.novoItem, this.defaultNovoItem)
      this.isEditable = false
    },
    deleteItem(item) {
      this.isEditable = false
      this.$emit('deleteItem', item.id, this.tipo)
    },
    save() {
      this.$emit('save', this.novoItem)

      Object.assign(this.novoItem, this.defaultNovoItem)
      this.isEditable = false
    },
    changeInput(e) {
      if (this.hasChangeInput) {
        this.$emit('changeInput', e.target.value)
      }
    },

    verificaFormulario() {
      this.formError = false
      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) this.formError = true
        alert(this.formError)
        this.$refs[f].validate(!this.form[f])
      })
    },
  },
}
</script>

<style lang="scss">
.card__multitarefas {
  .error--text {
    .v-text-field__slot {
      transition: border 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
      border-right: 2px solid #ff5252 !important;
    }
    .v-input__append-inner {
      > button {
        color: #ff5252 !important;
      }
    }
  }
  .v-input__slot {
    padding-right: 10px;

    &:hover {
      .v-text-field__slot {
        border-right: 1px solid rgba(0, 0, 0, 0.87);
      }

      .v-input__append-inner {
        > button {
          color: rgba(0, 0, 0, 0.87);
        }
      }
    }
    &:focus-within {
      .v-text-field__slot {
        border-right: 2px solid rgba(0, 0, 0, 0.87);
      }

      .v-input__append-inner {
        > button {
          color: rgba(0, 0, 0, 0.87);
        }
      }
    }

    .v-text-field__slot {
      transition: border 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
      border-right: 1px solid rgba(0, 0, 0, 0.38);
    }
    .v-input__append-inner {
      margin: 0;
      padding: 0;
      align-self: center;
      padding-left: 10px !important;
    }
  }

  .card__title {
    background: #00113a;
    color: #fff;
  }

  .line-data {
    height: 50px;
    border: 1px solid rgba(0, 0, 0, 0.38) !important;
  }

  .line-data__description-70 {
    width: 70%;
  }

  .line-data__description-55 {
    width: 55%;
  }

  .line-data__icons {
    width: 15%;
    border-left: 1px solid rgba(0, 0, 0, 0.38);
    cursor: pointer;
  }

  .line-data:not(:first-child) {
    margin-top: 15px;
  }

  div > .selected {
    transition: border 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    border: 2px solid #545454 !important;
    background-color: #00113a80 !important;
    color: white !important;

    > .line-data__icons {
      border-left: 2px solid #545454;

      > i {
        color: white;
      }
    }
  }
}
</style>
