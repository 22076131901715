<template>
  <validation-observer v-slot="{ invalid }">
    <v-card class="pa-2">
      <v-card-subtitle>Informações do Produto</v-card-subtitle>
      <v-card-text>
        <validation-provider name="descricao" rules="required">
          <v-text-field
            label="Descrição"
            name="descricao"
            v-model="forms.descricao"
          />
        </validation-provider>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn
          color="secondary"
          :disabled="invalid || descricaoInvalida"
          @click="save"
          >Salvar</v-btn
        >
      </v-card-actions>
    </v-card>
  </validation-observer>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'ConfigProdutoEdit',
  props: {
    produto: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      forms: {
        descricao: this.produto.descricao,
      },
    }
  },
  computed: {
    descricaoInvalida() {
      return (
        this.forms.descricao.toUpperCase() ===
        this.produto.descricao.toUpperCase()
      )
    },
  },
  methods: {
    ...mapActions('fornecedores', ['editarProduto']),
    ...mapActions('template', ['errorMessage', 'successMessage']),

    async save() {
      try {
        const payload = this.forms
        const { fornecedor, id } = this.produto
        await this.editarProduto({
          fornecedorID: fornecedor,
          produtoID: id,
          payload,
        })
        Object.assign(this.produto, payload)
        this.successMessage('Produto alterado com sucesso!')
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },
  },
}
</script>
