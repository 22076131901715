<template>
  <v-card class="mb-7">
    <v-card-text class="pa-5 border-bottom">
      <h3
        class="subtitle blue-grey--text text--darken-2 font-weight-regular mt-3"
      >
        Informações dos serviços
      </h3>
    </v-card-text>
    <v-card-text class="pa-5 border-bottom">
      <v-form>
        <div class="mt-4">
          <v-data-table
            :headers="headers"
            :items="servicosAutorizados"
            sort-by="descricao"
            ref="table"
            class="border"
            @toggle-select-all="toggleSelectAll"
            selectable-key="selected"
            show-select
          >
          <template v-slot:[`header.data-table-select`]>
            <v-checkbox color="rgba(0, 0, 0, 0.6)" v-model="isAllSelected" />
          </template>
          <template v-slot:[`body`]>
            <draggable v-model="servicosAutorizados" class="tbody">
              <tr v-for="(servico, index) in servicosAutorizados" :key="index">
                <td>
                  <v-checkbox
                    color="rgba(0, 0, 0, 0.6)"
                    v-model="servico.selected"
                    @click="changeSelected(servico.selected)"
                  />
                </td>
                <td>{{ servico.descricao }}</td>
                <td style="text-transform: capitalize;">{{ servico.tipoTarifa }}</td>
                 <td class="valor">
                   <vuetify-money
                      ref="valor"
                      v-model="servico.valor"
                      label="Valor"
                    />
                </td>  
                <td>
                  <v-checkbox color="rgba(0, 0, 0, 0.6)" v-model="servico.obrigatorio" />
                </td>
              </tr>
            </draggable>
          </template>
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title>Serviços</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
              </v-toolbar>
            </template>
            <template v-slot:no-data>
              <span>Sem serviços</span>
            </template>
          </v-data-table>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>

import { mapActions, mapState } from 'vuex';
import draggable from 'vuedraggable'

export default {
  props: {
    disabled: Boolean,
    value: {
      type: Object,
      required: true,
    },
  },
  components: {
    draggable,
  },
  data() {
    return {
      selected: [],
      isAllSelected: false,
      servicosAutorizados: [],
      headers: [
        { text: 'Descrição', value: 'descricao' },
        { text: 'Tipo de tarifa', value: 'tipoTarifa' },
        { text: 'Valor', value: 'valor' },
        { text: 'Obrigatório', value: 'obrigatorio' },
      ],
    }
  },
  computed: {
    ...mapState('auth', ['empresaAtual']),
    ...mapState('patio', ['servicos']),

    servicosSelecteds() {
      return this.servicosAutorizados
        .filter((servico) => !!servico.selected)
        .map((servico) => {
          return {
            servico: servico.id,
            obrigatorio: servico.obrigatorio,
            valor: servico.valor
          }
        })
    }
  },
  methods: {
    ...mapActions('patio', ['getServicos']),

    changeSelected(selected) {
      if (!selected && this.isAllSelected) {
        const servicosSelecteds = this.servicosAutorizados.map((servico) => servico.selected ? servico.id : null).filter((value) => !!value)
        this.isAllSelected = false

        this.$nextTick(() => {
          servicosSelecteds.forEach((id) => {
            const index = this.servicosAutorizados.findIndex((servico) => servico.id === id)
            
            if (index >= 0) {
              this.servicosAutorizados[index].selected = true
            }
          })
        })
      }
    },

    toggleSelectAll(value) {
      this.servicosAutorizados = this.servicosAutorizados.map((servico) => {
        return {
          ...servico,
          selected: value
        }
      })
    }
  },
  
  mounted() {
    if (!this.empresaAtual.public_id) return
    this.getServicos({ proprietario: this.empresaAtual.public_id })
  },

  watch: {
    servicos(val) {
      this.servicosAutorizados = val
    },
    isAllSelected(val) {
      this.toggleSelectAll(val)
    }
  }
}
</script>

<style lang="scss">
.tbody {
  border-bottom-color: rgb(128, 128, 128);
  border-collapse: separate;
  border-left-color: rgb(128, 128, 128);
  border-right-color: rgb(128, 128, 128);
  border-top-color: rgb(128, 128, 128);
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  display: table-row-group;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  height: 144px;
  line-height: 24px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  overflow-wrap: break-word;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  tab-size: 4;
  text-decoration-thickness: auto;
  text-indent: 0px;
  text-rendering: optimizelegibility;
  text-size-adjust: 100%;
  vertical-align: middle;
  white-space: normal;
  word-break: normal;

  tr {
    border-bottom-color: rgb(128, 128, 128);
    border-collapse: separate;
    border-left-color: rgb(128, 128, 128);
    border-right-color: rgb(128, 128, 128);
    border-top-color: rgb(128, 128, 128);
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.87);
    display: table-row;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    height: 48px;
    line-height: 24px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    overflow-wrap: break-word;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    tab-size: 4;
    text-decoration-thickness: auto;
    text-indent: 0px;
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    vertical-align: middle;
    white-space: normal;
    word-break: normal;

    .valor {
      width: 10%;
    }

    td {
      border-bottom-color: rgba(0, 0, 0, 0.12);
      border-bottom-style: solid;
      border-bottom-width: 1px;
      border-collapse: separate;
      box-sizing: border-box;
      color: rgba(0, 0, 0, 0.87);
      display: table-cell;
      font-family: Poppins, sans-serif;
      font-size: 14px;
      height: 48px;
      line-height: 24px;
      margin-bottom: 0px;
      margin-left: 0px;
      margin-right: 0px;
      margin-top: 0px;
      overflow-wrap: break-word;
      padding-bottom: 0px;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 0px;
      tab-size: 4;
      text-align: start;
      text-decoration-thickness: auto;
      text-indent: 0px;
      text-rendering: optimizelegibility;
      text-size-adjust: 100%;
      transition-delay: 0s;
      transition-duration: 0.2s;
      transition-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
      vertical-align: middle;
      white-space: normal;
      word-break: normal;
    }
  }
}
</style>