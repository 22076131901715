<template>
  <v-card class="mb-7">
    <v-card-text class="pa-5 border-bottom">
      <h3
        class="subtitle blue-grey--text text--darken-2 font-weight-regular mt-3"
      >
        Informações dos embalagens do contrato com o fornecedor
      </h3>
    </v-card-text>

    <v-card-text class="pa-5 border-bottom">
      <v-form>
        <div class="mt-4">
          <v-data-table
            :headers="headers"
            :items="embalagens"
            sort-by="Descricao"
            class="border"
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title>Embalagens registradas</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="!showForm"
                  color="primary"
                  dark
                  class="mb-2"
                  @click="showForm = !showForm"
                  >Nova Embalagem</v-btn
                >
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="editarEmbalagem(item)"
                >mdi-pencil</v-icon
              >
              <v-icon small @click="confirmarExcluirEmbalagem(item)"
                >mdi-delete</v-icon
              >
            </template>
            <template v-slot:no-data>
              <span>Sem embalagens adicionadas</span>
            </template>
          </v-data-table>
          <v-form v-if="showForm">
            <v-row>
              <v-toolbar flat color="white">
                <v-toolbar-title>Nova embalagem</v-toolbar-title>
              </v-toolbar>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" lg="12" class="pt-0">
                <v-text-field
                  label="Nome"
                  required
                  v-model="embalagem.descricao"
                ></v-text-field>
              </v-col>
            </v-row>
            <div class="pa-5 border-top text-right">
              <v-btn
                color="info"
                class="mr-2 text-capitalize"
                @click="salvarEmbalagem"
                >{{ textoBotaoSalvar }}</v-btn
              >
              <v-btn color="black" class="text-capitalize" dark @click="reset"
                >Cancelar</v-btn
              >
            </div>
          </v-form>
        </div>
      </v-form>
      <confirm-dialog @confirm="deleteEmbalagem" />
    </v-card-text>
  </v-card>
</template>

<style lang="scss">
.error__delete_title {
  color: #ff5252;
}
.error__delete_text {
  color: #545454;
}
</style>

<script>
import { mapActions } from 'vuex'
import ConfirmDialog from '@/components/template/ConfirmDialog'

// import CardMultitarefas from '../../../CardMultitarefas/CardMultitarefas.vue'

export default {
  name: 'DadosEmbalagem',
  components: {
    ConfirmDialog,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Nome',
          align: 'start',
          value: 'descricao',
        },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      embalagens: {},
      showForm: false,
      embalagem: {},
      edicao: false,
      indiceEdicao: -1,
    }
  },

  computed: {
    textoBotaoSalvar() {
      return this.edicao ? 'Alterar embalagem' : 'Adicionar embalagem'
    },
  },

  async created() {
    this.embalagens = this.value.embalagens
  },

  methods: {
    ...mapActions('fornecedores', [
      'adicionarEmbalagem',
      'atualizarEmbalagem',
      'excluirEmbalagem',
    ]),
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),

    reset() {
      this.embalagem = {}
      this.showForm = false
      this.edicao = false
    },

    async salvarEmbalagem() {
      if (!this.value.public_id) return
      this.embalagem.fornecedor = this.value.public_id
      if (this.edicao) {
        try {
          const novoEmbalagem = await this.atualizarEmbalagem(this.embalagem)
          this.successMessage('Embalagem atualizada com sucesso')
          this.embalagens.splice(this.indiceEdicao, 1, novoEmbalagem)
        } catch (error) {
          this.errorMessage('Erro ao atualizar embalagem: ' + error)
        }
      } else {
        try {
          const novoEmbalagem = await this.adicionarEmbalagem(this.embalagem)
          this.successMessage('Embalagem adicionada com sucesso')
          this.embalagens.push(novoEmbalagem)
        } catch (error) {
          this.errorMessage('Erro ao adicionar embalagem: ' + error)
        }
      }
    },

    editarEmbalagem(embalagem) {
      Object.assign(this.embalagem, embalagem)
      this.indiceEdicao = this.embalagens.indexOf(embalagem)
      this.showForm = true
      this.edicao = true
    },

    confirmarExcluirEmbalagem(embalagem) {
      this.embalagem = embalagem
      this.setDialogMessage({
        title: 'Confirmar exclusão?',
        message: 'Deseja mesmo excluir essa embalagem?',
      })
    },

    async deleteEmbalagem() {
      const id = this.embalagem.id
      try {
        await this.excluirEmbalagem({
          id,
          fornecedor: this.value.public_id,
        })
        const index = this.embalagens
          .map(function (x) {
            return x.id
          })
          .indexOf(id)
        this.embalagens.splice(index, 1)
        this.successMessage('Embalagem excluída com sucesso')
      } catch (error) {
        this.errorMessage('Erro ao excluir embalagem: ' + error)
      }
    },
  },
}
</script>
