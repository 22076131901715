<template>
  <v-card class="pa-2">
    <v-dialog v-model="showModal" max-width="20%">
      <config-produto-caracteristicas-add
        v-if="showModal"
        :editing="editing"
        :item="item"
        @save="save"
      />
    </v-dialog>

    <v-card-subtitle class="d-flex justify-space-between align-center">
      <span>Características do Produto</span>
      <v-btn color="primary" @click="addItem">Adicionar</v-btn>
    </v-card-subtitle>
    <v-card-text>
      <v-tabs icons-and-text v-model="tab">
        <v-tabs-slider></v-tabs-slider>
        <v-tab href="#ativos">Ativos</v-tab>
        <v-tab href="#inativos">Inativos</v-tab>

        <v-tabs-items v-model="tab">
          <v-tab-item value="ativos">
            <config-produto-caracteristicas-list
              :headers="headers"
              :items="caracteristicasAtivas"
              :produto="produto"
              @edit="editItem"
            />
          </v-tab-item>
          <v-tab-item value="inativos">
            <config-produto-caracteristicas-list
              :headers="headers"
              :items="caracteristicasInativas"
              :produto="produto"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import ConfigProdutoCaracteristicasAdd from './ConfigProdutoCaracteristicasAdd.vue'
import ConfigProdutoCaracteristicasList from './ConfigProdutoCaracteristicasList.vue'

export default {
  name: 'ConfigProdutoCaracteristicas',
  props: {
    produto: {
      type: Object,
      required: true,
    },
  },
  components: {
    ConfigProdutoCaracteristicasAdd,
    ConfigProdutoCaracteristicasList,
  },
  data() {
    return {
      tab: '',
      showModal: false,
      editing: false,
      item: {},
      headers: [
        {
          text: 'Descrição',
          value: 'descricao',
        },
        {
          text: 'Valor Mínimo',
          value: 'valor_minimo',
        },
        {
          text: 'Valor Máximo',
          value: 'valor_maximo',
        },
        {
          text: 'Tipo de Frete',
          value: 'tipo_frete',
        },
        {
          text: 'Unidade de Medida',
          value: 'unidade_medida',
        },
        {
          text: 'Código interno',
          value: 'codigo_interno',
        },
        {
          text: 'Ações',
          value: 'actions',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('fornecedores', [
      'caracteristicasAtivas',
      'caracteristicasInativas',
    ]),
  },
  methods: {
    ...mapActions('fornecedores', [
      'adicionarCaracteristica',
      'listCaracteristicas',
      'editarCaracteristica',
    ]),
    ...mapActions('template', ['errorMessage', 'successMessage']),

    addItem() {
      this.showModal = true
      this.editing = false
      this.item = {}
    },

    editItem(item) {
      this.showModal = true
      this.editing = true
      this.item = item
    },

    async add(payload) {
      try {
        await this.adicionarCaracteristica({
          fornecedorID: this.produto.fornecedor,
          produtoID: this.produto.id,
          payload,
        })
        this.successMessage('Característica adicionada com sucesso!')
        this.showModal = false
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },

    async edit(payload) {
      try {
        payload = {
          ...payload,
          tipo_frete: payload.tipo_frete.map((t) => t.id),
        }
        await this.editarCaracteristica({
          fornecedorID: this.produto.fornecedor,
          produtoID: this.produto.id,
          payload,
        })
        this.successMessage('Característica alterada com sucesso!')
        this.showModal = false
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },

    async save(payload) {
      if (this.editing) {
        await this.edit(payload)
      } else {
        await this.add(payload)
      }
    },

    async getCaracteristicas() {
      try {
        await this.listCaracteristicas({
          fornecedorID: this.produto.fornecedor,
          produtoID: this.produto.id,
        })
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },
  },

  async created() {
    await this.getCaracteristicas()
  },
}
</script>
