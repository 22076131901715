var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tabela-cadastro',{ref:"tabela",attrs:{"headers":_vm.headers,"items":_vm.tarifas,"custom_class":"table my-10","name":"Hora"},on:{"save":_vm.save,"prepareEdit":_vm.prepareEdit,"delete":_vm.deleteItem,"reset":function($event){_vm.form = Object.assign({}, _vm.formDefault)}},scopedSlots:_vm._u([{key:"form-cadastro",fn:function(ref){
var title = ref.title;
var close = ref.close;
return [_c('v-card',[_c('v-card-title',{staticClass:"v-card mb-4"},[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(title))])]),_c('v-card-text',{staticClass:"pa-5 border-bottom"},[_c('validation-observer',{ref:"observer"},[_c('form',{ref:"form",staticClass:"container"},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":"valor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vuetify-money',{ref:"valor",attrs:{"error-messages":errors,"label":"Valor fixo","options":_vm.options},model:{value:(_vm.form.valor),callback:function ($$v) {_vm.$set(_vm.form, "valor", $$v)},expression:"form.valor"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":"tolerancia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"tolerancia",attrs:{"type":"number","error-messages":errors,"label":"Tolerância","required":""},model:{value:(_vm.form.tolerancia),callback:function ($$v) {_vm.$set(_vm.form, "tolerancia", $$v)},expression:"form.tolerancia"}})]}}],null,true)})],1)],1),_vm._l((_vm.form.excedente),function(cobranca,index){return _c('v-card',{key:index,staticClass:"mt-6 pb-2 px-3"},[_c('v-row',[_c('v-col',{staticClass:"py-0 d-flex",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('validation-provider',{staticStyle:{"width":"80%"},attrs:{"name":"valor adicional","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vuetify-money',{ref:"valor",refInFor:true,attrs:{"error-messages":errors,"label":"Valor da hora adicional","options":_vm.options,"required":""},model:{value:(_vm.form.excedente[index].valor),callback:function ($$v) {_vm.$set(_vm.form.excedente[index], "valor", $$v)},expression:"form.excedente[index].valor"}})]}}],null,true)}),_c('v-icon',{staticStyle:{"width":"20%"},on:{"click":function($event){return _vm.form.excedente.splice(index, 1)}}},[_vm._v("mdi-delete")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":"excedente","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"excedente",refInFor:true,attrs:{"type":"number","error-messages":errors,"label":"Cobrança/Excedente a partir de","required":""},model:{value:(_vm.form.excedente[index].doravante),callback:function ($$v) {_vm.$set(_vm.form.excedente[index], "doravante", $$v)},expression:"form.excedente[index].doravante"}})]}}],null,true)})],1)],1)],1)}),_c('v-row',{staticClass:"justify-center mt-3"},[_c('v-btn',{attrs:{"elevation":"2","color":"info"},on:{"click":_vm.adicionarExcedente}},[_vm._v(" Adicionar cobrança/excedente ")])],1)],2)])],1),_c('v-card-actions',[_c('v-spacer'),_c('buttons-cadastro',{on:{"close":close,"save":_vm.save}})],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }