<template>
  <fragment>
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="5"
      :loading="loading"
      :class="custom_class"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>{{ name }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="dialogForm = true"
          >
            Novo {{ name }} 
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:[`item.valor`]="{ item }">
        <span>R$ {{ item.valor }}</span>
      </template>
      
      <template v-slot:[`item.valorAdicional`]="{ item }">
        <span>R$ {{ item.valorAdicional }}</span>
      </template>

      <template v-slot:[`item.tolerancia`]="{ item }">
        <span>{{ item.tolerancia }} hora(s)</span>
      </template>

      <template v-slot:[`item.limite`]="{ item }">
        <span>{{ item.limite }} hora(s)</span>
      </template>

      <template v-slot:[`item.peso`]="{ item }">
        <span>{{ `${item.peso} (${item.tipoPeso})` }}</span>
      </template>

      <template v-slot:[`item.subProduto`]="{ item }">
        <span>{{ item.subProduto.descricao }}</span>
      </template>

      <template v-slot:[`item.embalagem`]="{ item }">
        <span>{{ item.embalagem.descricao }}</span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="prepareDeleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>


    <v-dialog
      v-model="dialogForm"
      max-width="500px"
    >
      <slot name="form-cadastro" :title="title" :close="close" />
    </v-dialog>
    
    <v-dialog v-model="dialogDelete" max-width="400px">
      <v-card>
        <v-card-title class="v-card mb-4">
          <span class="headline">Deletar {{ name }}</span>
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="black darken-1" text @click="dialogDelete = false"
            >Cancelar</v-btn
          >
          <v-btn color="darken-1" style="background: #f00; color: white;" @click="deleteItem">Deletar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
  </fragment>
</template>
<script>
// import ButtonsCadastro from '../ButtonsCadastro/ButtonsCadastro.vue';

export default {
  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false
    },
    custom_class: {
      type: String || Object || Array,
      required: false
    },
    name: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      dialogForm: false,
      dialogDelete: false,
      isEdit: false,
      itemDelete: null
    }
  },
  computed: {
    title() {
      return this.isEdit ? `Alterar ${this.name.toLowerCase()}` : `Cadastrar ${this.name.toLowerCase()}`
    },
  },
  methods: {
    close() { this.dialogForm = false; this.dialogDelete = false; this.isEdit = false; this.$emit('reset'); },
  
    editItem(item) {
      this.isEdit = true;
      this.$emit('prepareEdit', item);
      this.dialogForm = true;
    },
    prepareDeleteItem(item) {
      this.dialogDelete = true;
      this.itemDelete = item
    },
    deleteItem() {
      this.$emit('delete', this.itemDelete)
    },
  },
  watch: {
    dialogForm(newVal) {
      if (newVal) return
      this.$emit('reset');
    }
  },
  components: {
    // ButtonsCadastro
  }
}

</script>