<template>
  <v-data-table :headers="headers" :items="items">
    <template #[`item.tipo_frete`]="{ item }">
      <span v-for="tipoFrete in item.tipo_frete" :key="tipoFrete.id">
        {{ tipoFrete.descricao }}
      </span>
    </template>
    <template #[`item.actions`]="{ item }">
      <v-icon
        color="secondary"
        @click="$emit('edit', item)"
        v-if="item.active"
        title="Editar Item"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        color="error"
        @click="inativarItem(item)"
        v-if="item.active"
        class="mx-1"
        title="Inativar Item"
      >
        mdi-cancel
      </v-icon>
      <v-icon
        color="secondary"
        @click="ativarItem(item)"
        v-if="!item.active"
        class="mx-1"
        title="Ativar Item"
      >
        mdi-undo
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'ConfigProdutoCaracteristicasList',
  props: {
    items: {
      type: Array,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    produto: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions('fornecedores', [
      'excluirCaracteristica',
      'undeleteCaracteristica',
    ]),
    ...mapActions('template', ['errorMessage', 'successMessage']),

    async inativarItem(item) {
      try {
        await this.excluirCaracteristica({
          fornecedorID: this.produto.fornecedor,
          produtoID: this.produto.id,
          caracteristicaID: item.public_id,
        })
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },

    async ativarItem(item) {
      try {
        await this.undeleteCaracteristica({
          fornecedorID: this.produto.fornecedor,
          produtoID: this.produto.id,
          caracteristicaID: item.public_id,
        })
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },
  },
}
</script>
