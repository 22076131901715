<template lang="">
  <tabela-cadastro
    :headers="headers"
    :items="tarifas"
    @save="save"
    @prepareEdit="prepareEdit"
    @delete="deleteItem"
    @reset="form = {...formDefault}"
    custom_class="table my-10"
    name="Embalagem"
    ref="tabela"
  >
    <template v-slot:form-cadastro="{ title, close }">
      <v-card>
        <v-card-title class="v-card mb-4">
          <span class="headline">{{ title }}</span>
        </v-card-title>
        <v-card-text class="pa-5 border-bottom">
          <validation-observer ref="observer">
            <form ref="form" class="container">
              <v-row>
                <v-col class="py-0" cols="12" sm="12" md="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="embalagem"
                    rules="required"
                  >
                    <v-select
                      ref="embalagem"
                      :items="listEmbalagens"
                      v-model="form.embalagem"
                      :error-messages="errors"
                      item-text="descricao"
                      item-value="id"
                      label="Embalagem"
                      required
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0" cols="12" sm="12" md="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="valor"
                    rules="required"
                  >
                    <vuetify-money
                      ref="valor"
                      v-model="form.valor"
                      :error-messages="errors"
                      label="Valor"
                      v-bind:options="options"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </form>
          </validation-observer>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <buttons-cadastro @close="close" @save="save" />
        </v-card-actions>
      </v-card>
    </template>
  </tabela-cadastro>
</template>
<script>

import { mapState, mapActions } from 'vuex'
import TabelaCadastro from '@/components/TabelaCadastro/TabelaCadastro.vue';
import ButtonsCadastro from '@/components/ButtonsCadastro/ButtonsCadastro.vue';

export default {
  name: 'Embalagem',
  props: {
    servico: {
      type: Object,
      required: true
    },
    servico_autorizado: {
      type: Boolean,
      default: false,
    },
  },
  components: { TabelaCadastro, ButtonsCadastro },
  data() {
    return {

      headers: [
        { text: 'Embalagem', value: 'embalagem' },
        { text: 'Valor', value: 'valor' },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      form: {
        id: null,
        embalagem: null,
        valor: null
      },
      formDefault: {
        id: null,
        embalagem: null,
        valor: null
      },
      options: {
        locale: "pt-BR",
        prefix: "R$",
        suffix: "",
        length: 11,
        precision: 2
      }
    }
  },

  computed: {
    ...mapState('patio', ['tarifas']),
    ...mapState('auth', ['empresaAtual']),
    ...mapState('fornecedores', ['fornecedores']),

    listEmbalagens() {
      let embalagensArray = [];
      console.log(this.fornecedores)
      this.fornecedores.map(fornecedor => {
        return fornecedor.embalagens;
      }).map(embalagens => {
        embalagens.forEach(embalagem => {
          embalagensArray.push({...embalagem})
        })
      })

      return embalagensArray
    },
  },

  mounted() {
    if (!this.empresaAtual.public_id) return
    this.getTarifas();
  },

  methods: {
    ...mapActions('patio', ['getServicoTarifa', 'getServicoAutorizadoTarifa']),
    ...mapActions('fornecedores', ['getFornecedores']),

    async getTarifas() {
      const get = this.servico_autorizado
        ? this.getServicoAutorizadoTarifa
        : this.getServicoTarifa
      await get({
        tipoTarifa: this.servico.tipoTarifa,
        proprietario: this.empresaAtual.public_id,
        ...(this.servico_autorizado
          ? { servico_autorizado: this.servico.public_id_autorizado }
          : { servico: this.servico.id }),
      })
    },

    validate() {
      this.$refs.observer.validate()
      const isValid = !this.$refs.observer.flags.invalid
      return isValid
    },

    reset() {
      this.form = {...this.formDefault}
      if (this.$refs.observer && this.$refs.observer.reset) {
        this.$refs.observer.reset()
      }
      this.$refs['tabela'].close()
    },

    async save() {
      if (!this.validate()) return

      if (this.$refs['tabela'].$data.isEdit) {
        await this.$emit('edit', { ...this.form, valor: this.form.valor, tipoTarifa: 'embalagem' })
      } else {
        await this.$emit('save', { ...this.form, valor: this.form.valor, tipoTarifa: 'embalagem' })
      }
    },

    prepareEdit(embalagem) {
      this.form = {...embalagem}
      return
    },

    deleteItem(tarifa) {
      this.$emit('deleteItem', { ...tarifa, tipoTarifa: 'embalagem' })
    }
  }
}
</script>
<style>
.table {
  width: 50%;
  margin: auto;
}
</style>