<template>
  <div class="text-center">
    <component
      :is="servico.tipoTarifa"
      ref="tarifa"
      :servico="servico"
      :fornecedorPublicID="this.fornecedorPublicID"
      :servico_autorizado="servico_autorizado"
      @edit="edit"
      @save="save"
      @deleteItem="deleteItem"
    />
  </div>
</template>
<script>
import Eixo from './tabs/Eixo.vue'
import Peso from './tabs/Peso.vue'
import Hora from './tabs/Hora.vue'
import Subproduto from './tabs/Subproduto.vue'
import Embalagem from './tabs/Embalagem.vue'

import { mapState, mapActions } from 'vuex'

export default {
  name: 'CadastroTarifa',
  props: {
    fornecedorPublicID: String,
    servico: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
    },
    servico_autorizado: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Eixo,
    Peso,
    Hora,
    Subproduto,
    Embalagem,
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('auth', ['empresaAtual']),
  },

  methods: {
    ...mapActions('patio', [
      'addServicoTarifa',
      'editServicoTarifa',
      'deleteServicoTarifa',
      'addServicoAutorizadoTarifa',
      'editServicoAutorizadoTarifa',
      'deleteServicoAutorizadoTarifa',
    ]),
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),

    async edit(editTarifa) {
      if (!this.empresaAtual.public_id) return

      try {
        let proprietarioPublicId = ""

        if(this.fornecedorPublicID != ""){
          proprietarioPublicId = this.fornecedorPublicID
        }else{
          proprietarioPublicId = this.empresaAtual.public_id
        }

        const edit = this.servico_autorizado
          ? this.editServicoAutorizadoTarifa
          : this.editServicoTarifa
        await edit({
          ...editTarifa,
          ...(this.servico_autorizado
            ? { servico_autorizado: this.servico.public_id_autorizado }
            : { servico: this.servico.id }),
          proprietario: proprietarioPublicId,
        })
      } catch (err) {
        this.errorMessage('Erro ao editar a tarifa:' + err.response.data)
      }
      this.reset()
    },

    async save(novaTarifa) {
      

      try {

        let proprietarioPublicId = ""
        console.log(this.fornecedorPublicID)
        if(this.fornecedorPublicID != ""){
          proprietarioPublicId = this.fornecedorPublicID
        }else{
          proprietarioPublicId = this.empresaAtual.public_id
        }

        const add = this.servico_autorizado
          ? this.addServicoAutorizadoTarifa
          : this.addServicoTarifa
        await add({
          ...novaTarifa,
          ...(this.servico_autorizado
            ? { servico_autorizado: this.servico.public_id_autorizado }
            : { servico: this.servico.id }),
          proprietario: proprietarioPublicId,
        })
      } catch (err) {
        if (err.response) {
            // There is an error response from the server
            // You can anticipate error.response.data here
            const error = err.response.data;
            console.log(error.message);
        } else if (err.request) {
            // The request was made but no response was received
            // Error details are stored in err.reqeust
            console.log(err.request);
        } else {
            // Some other errors
            console.log('Error', err.message);
        }
      }
      this.reset()
    },

    async deleteItem(tarifa) {
      if (!this.empresaAtual.public_id && tarifa) return

      try {
        await this.deleteServicoTarifa(tarifa)
      } catch (err) {
        this.errorMessage('Erro ao deletar a tarifa:' + err.response.data)
      }
      this.reset()
    },

    reset() {
      if (this.$refs['tarifa'] && this.$refs['tarifa'].reset) {
        this.$refs['tarifa'].reset()
      }
    },
  },
}
</script>
