<template>
  <validation-observer v-slot="{ invalid }">
    <v-card class="pa-4">
      <v-card-title> Cadastro de Característica </v-card-title>
      <v-card-text>
        <validation-provider
          name="descricao"
          rules="required"
          v-slot="{ errors }"
        >
          <v-text-field
            v-model="forms.descricao"
            label="Descrição"
            name="descricao"
            prepend-icon="mdi-label"
            :error-messages="errors"
          />
        </validation-provider>
        <validation-provider
          name="valor_minimo"
          :rules="{
            required: true,
            max_value: forms.valor_maximo,
          }"
          v-slot="{ errors }"
        >
          <v-text-field
            v-model="forms.valor_minimo"
            label="Valor Mínimo"
            name="valor_minimo"
            v-mask="['#.##', '##.##']"
            prepend-icon="mdi-clipboard-plus"
            :error-messages="errors"
          />
        </validation-provider>
        <validation-provider
          name="valor_maximo"
          :rules="{
            required: true,
            is_not: forms.valor_minimo,
            min_value: forms.valor_minimo,
          }"
          v-slot="{ errors }"
        >
          <v-text-field
            v-model="forms.valor_maximo"
            label="Valor Máximo"
            name="valor_maximo"
            v-mask="['#.##', '##.##']"
            prepend-icon="mdi-clipboard-plus"
            :error-messages="errors"
          />
        </validation-provider>
        <validation-provider
          name="tipo_frete"
          rules="required"
          v-slot="{ errors }"
        >
          <v-autocomplete
            v-model="forms.tipo_frete"
            label="Tipo de Frete"
            name="tipo_frete"
            multiple
            prepend-icon="mdi-dolly"
            item-text="descricao"
            item-value="id"
            :items="tiposFrete"
            :error-messages="errors"
          />
        </validation-provider>
        <validation-provider
          name="unidade_medida"
          rules="required"
          v-slot="{ errors }"
        >
          <v-autocomplete
            v-model="forms.unidade_medida"
            label="Unidade de Medida"
            name="unidade_medida"
            prepend-icon="mdi-tape-measure"
            item-text="name"
            item-value="id"
            :items="unidadesMedidas"
            :error-messages="errors"
          />
        </validation-provider>
        <v-text-field
          v-model="forms.codigo_interno"
          label="Código interno"
          name="codigo_interno"
          prepend-icon="mdi-label"
        />
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn
          color="secondary"
          @click="$emit('save', forms)"
          :disabled="invalid"
        >
          {{ editing ? 'Salvar' : 'Adicionar' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </validation-observer>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import { UnidadeMedida } from '@/utils/caracteristicas.js'

export default {
  name: 'ConfigProdutoCaracteristicasAdd',
  props: {
    item: {
      type: Object,
    },
    editing: {
      type: Boolean,
    },
  },
  data() {
    return {
      forms: {
        descricao: '',
        valor_minimo: '',
        valor_maximo: '',
        tipo_frete: '',
        unidade_medida: '',
      },
    }
  },
  computed: {
    ...mapState('patio', ['tiposFrete']),

    unidadesMedidas() {
      return Object.values(UnidadeMedida)
    },
  },
  methods: {
    ...mapActions('patio', ['getTiposFrete']),
    ...mapActions('template', ['errorMessage']),
  },
  async created() {
    if (this.editing) {
      Object.assign(this.forms, {
        ...this.item,
        valor_minimo: this.item.valor_minimo.toFixed(2),
        valor_maximo: this.item.valor_maximo.toFixed(2),
      })
    }
    try {
      await this.getTiposFrete()
    } catch (e) {
      this.errorMessage(e.response.data)
    }
  },
}
</script>
