<template>
  <v-card class="pa-4">
    <v-card-title>Configuração do Produto {{ produto.descricao }}</v-card-title>
    <v-divider class="my-4" />
    <config-produto-edit :produto="produto" />
    <v-divider class="my-4" />
    <config-produto-caracteristicas :produto="produto"/>
  </v-card>
</template>

<script>
import ConfigProdutoEdit from './ConfigProdutoEdit.vue'
import ConfigProdutoCaracteristicas from './ConfigProdutoCaracteristicas.vue'

export default {
  name: 'ProdutosFornecedorConfigProduto',
  props: {
    produto: {
      type: Object,
      required: true,
    },
  },
  components: {
    ConfigProdutoEdit,
    ConfigProdutoCaracteristicas,
  },
}
</script>
