var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',{staticClass:"pa-4"},[_c('v-card-title',[_vm._v(" Cadastro de Característica ")]),_c('v-card-text',[_c('validation-provider',{attrs:{"name":"descricao","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Descrição","name":"descricao","prepend-icon":"mdi-label","error-messages":errors},model:{value:(_vm.forms.descricao),callback:function ($$v) {_vm.$set(_vm.forms, "descricao", $$v)},expression:"forms.descricao"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"valor_minimo","rules":{
          required: true,
          max_value: _vm.forms.valor_maximo,
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['#.##', '##.##']),expression:"['#.##', '##.##']"}],attrs:{"label":"Valor Mínimo","name":"valor_minimo","prepend-icon":"mdi-clipboard-plus","error-messages":errors},model:{value:(_vm.forms.valor_minimo),callback:function ($$v) {_vm.$set(_vm.forms, "valor_minimo", $$v)},expression:"forms.valor_minimo"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"valor_maximo","rules":{
          required: true,
          is_not: _vm.forms.valor_minimo,
          min_value: _vm.forms.valor_minimo,
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['#.##', '##.##']),expression:"['#.##', '##.##']"}],attrs:{"label":"Valor Máximo","name":"valor_maximo","prepend-icon":"mdi-clipboard-plus","error-messages":errors},model:{value:(_vm.forms.valor_maximo),callback:function ($$v) {_vm.$set(_vm.forms, "valor_maximo", $$v)},expression:"forms.valor_maximo"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"tipo_frete","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Tipo de Frete","name":"tipo_frete","multiple":"","prepend-icon":"mdi-dolly","item-text":"descricao","item-value":"id","items":_vm.tiposFrete,"error-messages":errors},model:{value:(_vm.forms.tipo_frete),callback:function ($$v) {_vm.$set(_vm.forms, "tipo_frete", $$v)},expression:"forms.tipo_frete"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"unidade_medida","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Unidade de Medida","name":"unidade_medida","prepend-icon":"mdi-tape-measure","item-text":"name","item-value":"id","items":_vm.unidadesMedidas,"error-messages":errors},model:{value:(_vm.forms.unidade_medida),callback:function ($$v) {_vm.$set(_vm.forms, "unidade_medida", $$v)},expression:"forms.unidade_medida"}})]}}],null,true)}),_c('v-text-field',{attrs:{"label":"Código interno","name":"codigo_interno","prepend-icon":"mdi-label"},model:{value:(_vm.forms.codigo_interno),callback:function ($$v) {_vm.$set(_vm.forms, "codigo_interno", $$v)},expression:"forms.codigo_interno"}})],1),_c('v-card-actions',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"color":"secondary","disabled":invalid},on:{"click":function($event){return _vm.$emit('save', _vm.forms)}}},[_vm._v(" "+_vm._s(_vm.editing ? 'Salvar' : 'Adicionar')+" ")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }