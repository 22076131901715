<template>
  <v-card class="mb-7">
    <v-dialog v-model="showModalAddCodigoInterno" max-width="25%">
      <produtos-fornecedor-codigo-interno
        v-if="showModalAddCodigoInterno"
        :produto="produtoSelecionado.data"
        @save="editProduto"
      />
    </v-dialog>

    <v-dialog v-model="showModalConfigProduto" max-width="50%">
      <produtos-fornecedor-config-produto
        v-if="showModalConfigProduto"
        :produto="produtoSelecionado.data"
      />
    </v-dialog>

    <v-dialog v-model="showModalConfigSubProduto" max-width="50%">
      <produtos-fornecedor-config-sub-produto
        v-if="showModalConfigSubProduto"
        :subProduto="subProdutoSelecionado.data"
      />
    </v-dialog>

    <v-card-text class="pa-5 border-bottom">
      <h3
        class="subtitle blue-grey--text text--darken-2 font-weight-regular mt-3"
      >
        Informações dos produtos do cliente
      </h3>
    </v-card-text>
    <v-row class="d-flex justify-center py-5">
      <v-col cols="12" sm="12" md="4">
        <card-multitarefas
          nome="Produtos"
          placeholder="Novo produto..."
          :items="produtos"
          tipo="produto"
          @next="next"
          @deleteItem="confirmarExclusaoProduto"
          @save="criarProduto"
          @edit="editProduto"
          @prepareEdit="prepareProduto"
          @addCodigoInterno="addCodigoInterno"
          @configItem="configItem"
          :editable="true"
          :hasSelecionado="true"
          :hasNext="true"
          :hasEdit="true"
        />
      </v-col>

      <v-col cols="12" sm="12" md="4">
        <card-multitarefas
          nome="Subprodutos"
          placeholder="Novo subproduto..."
          :items="subProdutosA"
          tipo="subproduto"
          @deleteItem="confirmarDesativacaoSubProduto"
          @save="criarSubProduto"
          @prepareEdit="prepareSubProduto"
          @configSubProdutoItem="configSubProdutoItem"
          :editable="produtoSelecionado.selecionado"
          :hasSelecionado="produtoSelecionado.selecionado"
          :hasNext="false"
          :hasEdit="true"
        />
      </v-col>

      <v-dialog v-model="dialogEdit" max-width="500px">
        <v-card>
          <v-card-title>
            <v-card-text>
              <h3 class="transition-swing text-h3 mb--1">Editar</h3>
            </v-card-text>
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" text @click="dialogEdit = false">
              Fechar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <confirm-dialog @confirm="deleteItem" />
  </v-card>
</template>



<script>
import { mapState, mapActions } from 'vuex'
import ConfirmDialog from '@/components/template/ConfirmDialog'
import CardMultitarefas from '@/components/CardMultitarefas/CardMultitarefas.vue'

import ProdutosFornecedorCodigoInterno from './ProdutosFornecedorCodigoInterno.vue'
import ProdutosFornecedorConfigProduto from './ProdutosFornecedorConfigProduto.vue'
import ProdutosFornecedorConfigSubProduto from './ProdutosFornecedorConfigSubProduto.vue'

export default {
  name: 'ProdutosFornecedor',
  components: {
    CardMultitarefas,
    ConfirmDialog,
    ProdutosFornecedorCodigoInterno,
    ProdutosFornecedorConfigSubProduto,
    ProdutosFornecedorConfigProduto,
  },

  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      produtos: [],
      toDelete: {},
      showModalAddCodigoInterno: false,
      showModalConfigProduto: false,
      showModalConfigSubProduto: false,
      clienteSelecionado: {
        data: {},
        selecionado: false,
      },
      produtoSelecionado: {
        data: {},
        selecionado: false,
      },
      subProdutoSelecionado: {
        data: {},
        selecionado: false,
      },
      selecionadoDefault: {
        data: {},
        selecionado: false,
      },
      ultimoProdutoCriado: {},
      ultimoSubProdutoCriado: {},
      hasErrorDelete: false,
      errorName: 'pátio',
      dialogEdit: false,
    }
  },

  computed: {
    ...mapState('subProdutos', ['subProduto']),
    ...mapState('auth', ['empresaAtual']),

    subProdutosA() {
      if (this.produtoSelecionado.selecionado) {
        const subProdutos = this.produtos.filter(
          (produto) => produto.id === this.produtoSelecionado.data.id
        )[0].subprodutos
        return subProdutos
      } else {
        return []
      }
    },
  },

  created() {
    Object.assign(this.produtos, this.value.produtos)
  },

  methods: {
    ...mapActions('fornecedores', [
      'adicionarProduto',
      'desativarProduto',
      'editarProduto',
      'editarSubProduto',
      'adicionarSubProduto',
      'desativarSubProduto',
    ]),
    ...mapActions('template', [
      'errorMessage',
      'successMessage',
      'setDialogMessage',
    ]),

    configItem(item) {
      this.showModalConfigProduto = true
      this.produtoSelecionado.data = item
    },

    configSubProdutoItem(item) {
      console.log(item)
      this.showModalConfigSubProduto = true
      this.subProdutoSelecionado.data = item
    },

    addCodigoInterno(item) {
      this.showModalAddCodigoInterno = true
      this.produtoSelecionado.data = item
    },

    prepareProduto(item) {
      this.produtoSelecionado.data = item
    },
    prepareSubProduto(item) {
      this.subProdutoSelecionado.data = item
    },

    next(item) {
      if (this.produtoSelecionado.data.id !== item.id) {
        Object.assign(this.subProdutoSelecionado, this.selecionadoDefault)
      }
      this.produtoSelecionado.selecionado = true
      this.produtoSelecionado.data = item
    },

    confirmarExclusaoProduto(id, tipo) {
      this.toDelete = {
        id: id,
        tipo: tipo,
      }
      this.setDialogMessage({
        title: 'Confirmar Inativação?',
        message: 'Deseja mesmo inativar esse produto?',
      })
    },

    confirmarDesativacaoSubProduto(id, tipo) {
      this.toDelete = {
        id: id,
        tipo: tipo,
      }
      this.setDialogMessage({
        title: 'Confirmar Inativação?',
        message: 'Deseja mesmo inativar esse SubProduto?',
      })
    },

    async deleteItem() {
      const { id, tipo } = this.toDelete 
      switch (tipo) {
        
        case 'produto':
          try {
            await this.desativarProduto({
              id,
              fornecedor: this.value.public_id,
            })
            const index = this.produtos
              .map(function (x) {
                return x.id
              })
              .indexOf(id)
            this.produtos.splice(index, 1)
            this.produtoSelecionado = {
              selecionado: false,
              data: {},
            }
            this.successMessage('Produto inativado com sucesso')
          } catch (error) {
            this.errorMessage('Erro ao inativar produto: ' + error)
          }
          break
        case 'subproduto':
          try {
            await this.desativarSubProduto(id)
            const indexProduto = this.produtos
              .map(function (x) {
                return x.id
              })
              .indexOf(this.produtoSelecionado.data.id)
            const index = this.produtos[indexProduto].subprodutos
              .map(function (x) {
                return x.id
              })
              .indexOf(id)
            this.produtos[indexProduto].subprodutos.splice(index, 1)
            this.successMessage('SubProduto inativado com sucesso.')
          } catch (error) {
            this.errorMessage('Erro ao inativar o subProduto: ' + error)
          }
          break
      }
    },

    async buscarCliente() {},

    async criarProduto(produto) {
      produto.fornecedor = this.value.public_id
      try {
        const novoProduto = await this.adicionarProduto(produto)
        this.produtos.push(novoProduto)
        this.$forceUpdate()
        this.successMessage('Produto adicionado com sucesso.')
      } catch (error) {
        this.errorMessage('Erro ao adicionar o produto: ' + error)
      }
    },

    async criarSubProduto(data) {
      data.produto = this.produtoSelecionado.data.id
      try {
        const novoSubProduto = await this.adicionarSubProduto(data)
        const index = this.produtos.findIndex(
          (produto) => produto.id === data.produto
        )

        this.produtos[index].subprodutos.push({
          id: novoSubProduto.id,
          descricao: novoSubProduto.descricao,
        })
        // if (!this.produtos[index].subprodutos) this.produtos[index].subprodutos = [{ id: novoSubProduto.id, descricao: novoSubProduto.descricao }]
        // else if (this.produtos[index].subprodutos.length) this.produtos[index].subprodutos.push({ id: novoSubProduto.id, descricao: novoSubProduto.descricao })
        this.successMessage('SubProduto adicionado com sucesso.')
      } catch (error) {
        this.errorMessage('Erro ao adicionar subProduto: ' + error)
      }
    },

    criarCliente() {
      this.$router.push({
        name: 'Empresas',
        params: {
          irParaCadastro: true,
        },
      })
    },

    async editProduto(payload) {
      try {
        const { fornecedor, id } = this.produtoSelecionado.data
        await this.editarProduto({
          fornecedorID: fornecedor,
          produtoID: id,
          payload,
        })
        Object.assign(this.produtoSelecionado.data, payload)
        if (this.showModalAddCodigoInterno) {
          this.showModalAddCodigoInterno = false
        }
        this.successMessage('Produto alterado com sucesso!')
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },

    
  },
}
</script>

<style lang="scss">
.error__delete_title {
  color: #ff5252;
}
.error__delete_text {
  color: #545454;
}
</style>
