<template>
  <v-card class="mb-7">
    <v-card-text class="pa-5 border-bottom">
      <h3
        class="subtitle blue-grey--text text--darken-2 font-weight-regular mt-3"
      >
        Sobre a empresa
      </h3>
      <span class="red--text"
        >Dados editáveis apenas pelo administrador da empresa</span
      >
    </v-card-text>
    <validation-observer ref="observer">
      <v-form readonly>
        <v-card-text class="pa-5 border-bottom">
          <v-row>
            <v-col cols="12" sm="6" lg="6" class="pt-0">
              <validation-provider
                v-slot="{ errors }"
                name="Nome Fantasia"
                rules="required"
              >
                <v-text-field
                  label="Nome Fantasia"
                  :error-messages="errors"
                  v-model="empresa.business_name"
                  disabled
                  required
                ></v-text-field>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="Razão Social"
                rules="required"
              >
                <v-text-field
                  label="Razão Social"
                  :error-messages="errors"
                  v-model="empresa.trade_name"
                  disabled
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" sm="6" lg="6" class="pt-0">
              <v-text-field
                label="CNPJ"
                readonly
                v-model="empresa.cnpj"
                disabled
                v-mask="['##.###.###/####-##']"
              />
              <v-text-field
                disabled
                label="IE"
                v-model="empresa.ie"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="pa-5">
          <h3
            class="subtitle blue-grey--text text--darken-2 font-weight-regular"
          >
            Endereço
          </h3>
          <v-row class="mt-3">
            <v-col class="12" sm="6" lg="6">
              <v-text-field
                label="Endereço"
                v-model="empresa.address"
                disabled
              ></v-text-field>
            </v-col>
            <v-col class="12" sm="6" lg="6">
              <v-text-field
                label="Bairro"
                v-model="empresa.district"
                disabled
              ></v-text-field>
            </v-col>
            <v-col class="12" sm="6" lg="6">
              <v-text-field
                label="Cidade"
                v-model="empresa.city"
                disabled
              ></v-text-field>
            </v-col>
            <v-col class="12" sm="6" lg="6">
              <v-text-field
                label="CEP"
                v-mask="['#####-###']"
                v-model="empresa.zipCode"
                disabled
              ></v-text-field>
            </v-col>
            <v-col class="12" sm="6" lg="6">
              <v-select
                label="Estado"
                :items="estados"
                v-model="empresa.state"
                disabled
                item-text="nome"
                item-value="nome"
              ></v-select>
            </v-col>
            <v-col class="12" sm="6" lg="6">
              <v-text-field
                label="Telefone"
                v-model="empresa.phone"
                disabled
                v-mask="['(##) #####-####', '(##) ####-####']"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
    </validation-observer>
  </v-card>
</template>

<script>
import { validationMixin } from 'vuelidate'

import UtilsApi from '@/services/utils/utils.api'

// import { required } from 'vuelidate/lib/validators'

export default {
  mixins: [validationMixin],

  props: {
    cliente: Object,
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      empresa: this.value,
      estados: [],
    }
  },
  watch: {
    value(val) {
      Object.assign(this.empresa, val)
      this.$forceUpdate()
    },
  },
  methods: {
    async getEstados() {
      this.estados = await UtilsApi.getEstadosBrasil()
    },

    dadosEditar() {
      // if (this.empresaEdita && this.empresaEdita.id) {
      //   Object.assign(this.cliente, this.empresaEdita)
      //   this.cliente.zipCode = this.empresaEdita.zip_code
      // }
    },
  },
  created() {
    this.getEstados()
    this.dadosEditar()
  },
  validations: {
    // empresa: {
    //   business_name: {
    //     required,
    //   },
    // },
    // form: ['business_name'],
  },
  computed: {
    // erroNomeFantasia() {
    //   const errors = []
    //   if (!this.$v.empresa.business_name.$dirty) return errors
    //   !this.$v.empresa.business_name.required &&
    //     errors.push('Campo obrigatório')
    //   return errors
    // },
  },
}
</script>

<style>
</style>