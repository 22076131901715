<template>
  <div class="text-center">
    <form-wizard
      @on-complete="submitAdicionarFornecedor"
      color="#00aa9e"
      class="cadastroEmpresa"
      :title="null"
      :subtitle="null"
    >
      <tab-content
        title="CPF/CNPJ do Fornecedor"
        icon="ti-user"
        :before-change="validarCPFCNPJInformado"
      >
        <etapa-cnpj
          ref="etapaCnpj"
          v-model="cnpjCpf"
          :cnpjProp="cnpjCpf"
        ></etapa-cnpj>
      </tab-content>

      <tab-content
        title="Informações Gerais"
        icon="ti-settings"
        :before-change="validarEmpresa"
      >
        <etapa-dados-empresa
          v-model="fornecedor"
          :novaEmpresa="!empresaJaExistente"
          :disabled="empresaJaExistente"
          :estados="estados"
          ref="EtapaDadosEmpresa"
        ></etapa-dados-empresa>
      </tab-content>
      <tab-content title="Informações do contato" icon="ti-check">
        <etapa-dados-contrato
          v-model="fornecedor"
          :disabled="empresaEdita.id ? true : false"
          @atualizar-contatos="atualizarContatos"
          ref="etapaDadosContrato"
        ></etapa-dados-contrato>
      </tab-content>
      <tab-content
        title="Informações dos serviços autorizados"
        icon="ti-check"
      >
        <etapa-servicos-autorizados
          v-model="fornecedor"
          ref="etapaServicosAutorizados"
        ></etapa-servicos-autorizados>
      </tab-content>
      <v-btn color="black" dark slot="prev">
        <i class="mdi mdi-chevron-left mr-1"></i>
        Anterior
      </v-btn>
      <v-btn color="info" slot="next">
        Próximo
        <i class="mdi mdi-chevron-right ml-1"></i>
      </v-btn>
      <v-btn
        color="success"
        slot="finish"
        :disabled="empresaEdita.id ? true : false"
      >
        Finalizar
        <i class="mdi mdi-bookmark-check ml-1"></i>
      </v-btn>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { cpf, cnpj } from 'cpf-cnpj-validator'

import UtilsApi from '@/services/utils/utils.api'

import EtapaCnpj from '@/components/cadastros/geral/EtapaCnpj'
import EtapaDadosEmpresa from '@/components/cadastros/geral/EtapaDadosEmpresa'
import EtapaDadosContrato from './EtapaDadosContrato'
import EtapaServicosAutorizados from './EtapaServicosAutorizados'

import TenantsApi from '@/services/tenants'

import { mapState, mapActions } from 'vuex'
// const cnpjValido = (value) => cnpj.isValid(cnpj.strip(value))

import { jsonp } from 'vue-jsonp'

export default {
  props: ['empresaEdita'],
  data() {
    return {
      tab: null,
      title: 'Adicionar Fornecedores',
      fornecedor: {},
      estados: [],
      cnpjCpf: '',
      empresaJaExistente: false,
      novoFornecedor: {
        contatos: {},
      },
      hasError: false,
    }
  },

  components: {
    FormWizard,
    TabContent,
    EtapaCnpj,
    EtapaDadosEmpresa,
    EtapaDadosContrato,
    EtapaServicosAutorizados
  },
  methods: {
    ...mapActions('tenants', ['addEmpresa']),
    ...mapActions('template', ['errorMessage', 'successMessage']),
    ...mapActions('fornecedores', ['adicionarFornecedor']),

     async getEstados() {
      this.estados = await UtilsApi.getEstadosBrasil()
    },

    validarCPFCNPJInformado() {
      const refToValidate = this.$refs['etapaCnpj']
      const isValid = refToValidate.validate()

      this.hasError = !isValid

      this.verificarCPFCNPJExistente()

      return isValid
    },
    async validarEmpresa() {
      if (this.empresaJaExistente) {
        this.novoFornecedor.empresa     = this.empresaAtual.public_id
        this.novoFornecedor.fornecedor  = this.fornecedor.public_id

        this.hasError = false

        return true
      } else {
        const refToValidate = this.$refs['EtapaDadosEmpresa']
        const isValid = refToValidate.validate()

        this.hasError = !isValid

        return isValid
      }
    },
    async submitAdicionarFornecedor() {
      try{
        const campoDigitado = this.fornecedor.cpf ? 'cpf' : 'cnpj'
        const campoStriped =
          campoDigitado === 'cpf'
            ? cpf.strip(this.fornecedor.cpf)
            : cnpj.strip(this.fornecedor.cnpj)

        let empresa = {
          ...this.fornecedor,
          tenant_criador: this.empresaAtual.public_id,
          [campoDigitado]: campoStriped,
        }

        if (!this.empresaJaExistente) {
          empresa = await this.addEmpresa(empresa)
        }

        this.validarServicosAutorizados()
        this.novoFornecedor.empresa = this.empresaAtual.public_id
        this.novoFornecedor.fornecedor = empresa.public_id
        this.hasError = false

        await this.adicionarFornecedor(this.novoFornecedor)
        this.successMessage('Fornecedor adicionado com sucesso.')
        this.$emit('addedEmpresa')

      } catch (error){
        this.hasError = true
        this.errorMessage(error.response.data)
      }
    },
    reset() {
      this.$emit('reset')
    },
    atualizarContatos(contatos) {
      Object.assign(this.novoFornecedor.contatos, contatos)
    },
    async verificarCPFCNPJExistente() {
      const cnpjInformado = cnpj.isValid(this.cnpjCpf)

      if (cnpjInformado) {
        try {
          const cnpjFornecedor = cnpj.strip(this.cnpjCpf)
          const fornecedores = await TenantsApi.getTenantByCPFOrCNPJ({
            cnpj: cnpjFornecedor,
          })

          if (fornecedores.length > 0) {
            this.empresaJaExistente = true
            this.fornecedor = {... fornecedores[0] }
          }
          else {
            this.empresaJaExistente = false
            this.fornecedor = { cnpj: cnpjFornecedor }
            const dadosEmpresaReceita = await this.fetchDadosEmpresaReceita()
            this.syncDadosClienteComDadosReceita(dadosEmpresaReceita)
          }
        } catch (e) {
          this.errorMessage(e.response.data)
        }
      }
      else {
        try {
          const cpfFornecedor = cpf.strip(this.cnpjCpf)
          const fornecedores = await TenantsApi.getTenantByCPFOrCNPJ({
            cpf: cpfFornecedor,
          })

          if (fornecedores.length > 0){
            this.empresaJaExistente = true
            this.fornecedor = {... fornecedores[0] }
          }
          else {
            this.fornecedor = { cpf: cpfFornecedor}
          }

        } catch (e) {
          this.errorMessage(e.response.data)
        }
      }
    },

    syncDadosClienteComDadosReceita(dadosEmpresaReceita) {
      if (!dadosEmpresaReceita.message) {
        this.fornecedor = {
          business_name: dadosEmpresaReceita.fantasia,
          trade_name: dadosEmpresaReceita.nome,
          address: dadosEmpresaReceita.logradouro,
          district: dadosEmpresaReceita.bairro,
          city: dadosEmpresaReceita.municipio,
          zip_code: dadosEmpresaReceita.cep,
          cnpj: dadosEmpresaReceita.cnpj,
          numero: dadosEmpresaReceita.numero,
          complemento: dadosEmpresaReceita.complemento
        }
        const estadoEncontrado = this.estados.filter(
          (estado) => estado.sigla === dadosEmpresaReceita.uf
        )
        if (!!estadoEncontrado && !!estadoEncontrado[0]) {
          this.fornecedor = { ...this.fornecedor, state: estadoEncontrado[0].nome }
        }
        if (dadosEmpresaReceita.telefone) {
          this.fornecedor = {
            ...this.fornecedor,
            phone: dadosEmpresaReceita.telefone.replace(/[(, ), -]+/g, ''),
          }
        }
      }
    },

    async fetchDadosEmpresaReceita() {
      try{
        const qCnpj = await jsonp(
          `https://www.receitaws.com.br/v1/cnpj/${this.fornecedor.cnpj}`
        )
        return Promise.resolve(qCnpj)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    dadosEditar() {
      if (this.empresaEdita.public_id) {
        Object.assign(this.fornecedor, this.empresaEdita)
        this.fornecedor.zipCode = this.empresaEdita.zip_code
        this.cnpj = this.empresaEdita.cnpj
      }
    },

    validarServicosAutorizados() {
      this.novoFornecedor.servicos_autorizados = this.$refs.etapaServicosAutorizados.servicosSelecteds
    }
  },
  created() {
    this.dadosEditar()
    this.getEstados()
  },
  computed: {
    ...mapState('auth', ['empresaAtual']),
  },
}
</script>
