<template>
  <v-container fluid>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard
          heading="Fornecedores"
          :class="{ tabs: empresaEdita.public_id }"
        >
          <form-adicionar-fornecedor
            v-if="showForm || irParaCadastro"
            @addedEmpresa="showForm = false"
            :empresaEdita="empresaEdita"
          />
          <form-editar-fornecedor
            v-else-if="editarFornecedor"
            @cancelar="() => (editarFornecedor = false)"
            :fornecedor="fornecedorSelecionado"
          />
          <listar-empresas-relacao
            v-else
            v-model="fornecedores"
            :tela="permission"
            title="Fornecedores/indústrias registrados"
            titleNew="Novo Fornecedor"
            campoId="fornecedor_id"
            @update="atualizarListaFornecedores"
            @delete="confirmaExcluirFornecedor"
            @showForm="showForm = true"
            @editar="editar"
          />
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss">
.tabs {
  .pa-4 {
    padding: 0 !important;

    .cadastroEmpresa {
      padding: 16px;
      margin-top: 32px;
    }
  }
}
</style>

<script>
import { mapActions, mapState } from 'vuex'
import ListarEmpresasRelacao from '@/components/empresaRelacao/ListarEmpresasRelacao'
import FormAdicionarFornecedor from '@/components/comercial/fornecedores/adicionarFornecedor/FormAdicionarFornecedor'
import FormEditarFornecedor from '@/components/comercial/fornecedores/editarFornecedor/FormEditarFornecedor'
import { BasicPermissions, Sequences } from "@/utils/permissions"

export default {
  props: ['irParaCadastro'],
  data() {
    return {
      Sequences,
      showForm: false,
      editarFornecedor: false,
      page: {
        title: 'Gerenciamento de fornecedores / indústria',
      },
      breadcrumbs: [
        {
          text: 'Geral',
          disabled: false,
        },
        {
          text: 'Fornecedores',
          disabled: true,
        },
      ],
      fornecedorSelecionado: {},
      empresaEdita: {},
    }
  },
  components: {
    ListarEmpresasRelacao,
    FormAdicionarFornecedor,
    FormEditarFornecedor,
  },
  async created() {
    if (this.canList) {
      await this.atualizarListaFornecedores()
    }
  },
  computed: {
    ...mapState('fornecedores', ['fornecedores']),
    
    permission() {
      return Sequences.Fornecedores.toString()
    },
    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
  },
  methods: {
    ...mapActions('fornecedores', ['getFornecedores', 'excluirFornecedor']),
    ...mapActions('template', ['errorMessage', 'successMessage']),

    editar(fornecedor) {
      this.fornecedorSelecionado = fornecedor
      this.editarFornecedor = true
    },
    async atualizarListaFornecedores() {
      try {
        await this.getFornecedores()
      } catch (error) {
        if (error.response && error.response.status == 404) {
          this.errorMessage('Empresa não é um gate.')
        } else {
          this.errorMessage('Ocorreu algum erro inesperado.')
        }
      }
    },
    async confirmaExcluirFornecedor(fornecedor) {
      try {
        this.excluirFornecedor(fornecedor)
        this.successMessage('Empresa excluída com sucesso')
      } catch (error) {
        this.errorMessage('Erro ao excluir empresa')
      }
    },
  },
}
</script>

<style></style>
