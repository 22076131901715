var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tabela-cadastro',{ref:"tabela",attrs:{"headers":_vm.headers,"items":_vm.tarifas,"custom_class":"table my-10","name":"Eixo"},on:{"save":_vm.save,"prepareEdit":_vm.prepareEdit,"delete":_vm.deleteItem,"reset":function($event){_vm.form = Object.assign({}, _vm.formDefault)}},scopedSlots:_vm._u([{key:"form-cadastro",fn:function(ref){
var title = ref.title;
var close = ref.close;
return [_c('v-card',[_c('v-card-title',{staticClass:"v-card mb-4"},[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(title))])]),_c('v-card-text',{staticClass:"pa-5 border-bottom"},[_c('validation-observer',{ref:"observer"},[_c('form',{ref:"form",staticClass:"container"},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":"eixo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"eixo",attrs:{"error-messages":errors,"label":"Eixo","required":""},model:{value:(_vm.form.eixo),callback:function ($$v) {_vm.$set(_vm.form, "eixo", $$v)},expression:"form.eixo"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":"valor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vuetify-money',{ref:"valor",attrs:{"error-messages":errors,"label":"Valor","options":_vm.options},model:{value:(_vm.form.valor),callback:function ($$v) {_vm.$set(_vm.form, "valor", $$v)},expression:"form.valor"}})]}}],null,true)})],1)],1)],1)])],1),_c('v-card-actions',[_c('v-spacer'),_c('buttons-cadastro',{on:{"close":close,"save":_vm.save}})],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }