<template lang="">
  <tabela-cadastro
    :headers="headers"
    :items="tarifas"
    @save="save"
    @prepareEdit="prepareEdit"
    @delete="deleteItem"
    @reset="form = {...formDefault}"
    custom_class="table my-10"
    name="Eixo"
    ref="tabela"
  >
    <template v-slot:form-cadastro="{ title, close }">
      <v-card>
        <v-card-title class="v-card mb-4">
          <span class="headline">{{ title }}</span>
        </v-card-title>
        <v-card-text class="pa-5 border-bottom">
          <validation-observer ref="observer">
            <form ref="form" class="container">
              <v-row>
                <v-col class="py-0" cols="12" sm="12" md="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="eixo"
                    rules="required"
                  >
                    <v-text-field
                      ref="eixo"
                      v-model="form.eixo"
                      :error-messages="errors"
                      label="Eixo"
                      required
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0" cols="12" sm="12" md="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="valor"
                    rules="required"
                  >
                    <vuetify-money
                      ref="valor"
                      v-model="form.valor"
                      :error-messages="errors"
                      label="Valor"
                      v-bind:options="options"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </form>
          </validation-observer>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <buttons-cadastro @close="close" @save="save" />
        </v-card-actions>
      </v-card>
    </template>
  </tabela-cadastro>
</template>
<script>

import { mapState, mapActions } from 'vuex'
import TabelaCadastro from '@/components/TabelaCadastro/TabelaCadastro.vue';
import ButtonsCadastro from '@/components/ButtonsCadastro/ButtonsCadastro.vue';

export default {
  name: 'Eixo',
  props: {
    fornecedorPublicID: String,
    servico: {
      type: Object,
      required: true
    },
    servico_autorizado: {
      type: Boolean,
      default: false,
    },
  },
  components: { TabelaCadastro, ButtonsCadastro },
  data() {
    return {

      headers: [
        { text: 'Eixo', value: 'eixo' },
        { text: 'Valor', value: 'valor' },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      form: {
        id: null,
        eixo: null,
        valor: null
      },
      formDefault: {
        id: null,
        eixo: null,
        valor: null
      },
      options: {
        locale: "pt-BR",
        prefix: "R$",
        suffix: "",
        length: 11,
        precision: 2
      }
    }
  },

  computed: {
    ...mapState('patio', ['tarifas']),
    ...mapState('auth', ['empresaAtual']),
  },

  mounted() {
    this.getTarifas();
  },

  methods: {
    ...mapActions('patio', ['getServicoTarifa', 'getServicoAutorizadoTarifa']),
    async getTarifas() {
      let proprietarioPublicId = ""
      if(this.fornecedorPublicID != ""){
        proprietarioPublicId = this.fornecedorPublicID
      }else{
        proprietarioPublicId = this.empresaAtual.public_id
      }

      const get = this.servico_autorizado
        ? this.getServicoAutorizadoTarifa
        : this.getServicoTarifa
      await get({
        tipoTarifa: this.servico.tipoTarifa,
        proprietario: proprietarioPublicId,
        ...(this.servico_autorizado
          ? { servico_autorizado: this.servico.public_id_autorizado }
          : { servico: this.servico.id }),
      })
    },

    validate() {
      this.$refs.observer.validate()
      const isValid = !this.$refs.observer.flags.invalid
      return isValid
    },

    reset() {
      this.form = {...this.formDefault}
      if (this.$refs.observer && this.$refs.observer.reset) {
        this.$refs.observer.reset()
      }
      this.$refs['tabela'].close()
    },

    async save() {
      if (!this.validate()) return

      if (this.$refs['tabela'].$data.isEdit) {
        await this.$emit('edit', { ...this.form, valor: this.form.valor, tipoTarifa: 'eixo' })
      } else {
        await this.$emit('save', { ...this.form, valor: this.form.valor, tipoTarifa: 'eixo' })
      }
    },

    prepareEdit(eixo) {
      this.form = {...eixo}
      return
    },

    deleteItem(tarifa) {
      this.$emit('deleteItem', { ...tarifa, tipoTarifa: 'eixo' })
    }

  }
}
</script>
<style>
.table {
  width: 50%;
  margin: auto;
}
</style>