<template>
  <div>
    <v-card v-if="formAdicionarAdminEmpresa">
      <FormFieldsAdicionarUsuario
        cadastroAdminEmpresa
        :tipo="tipo"
        @close="formAdicionarAdminEmpresa = false"
        @save="adicionarContaAdministrativa"
        :tenantAdminEmpresa="this.empresaRelacaoAtual"
      />
    </v-card>
    <div v-else class="mt-4">
      <v-card-title v-if="canList">
        Filtro
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisa"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="value"
        :search="search"
        sort-by="descricao"
        class="border"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>{{ title }}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-icon
              v-if="canList"
              medium
              @click="$emit('update')"
              title="Atualizar"
              >mdi-refresh</v-icon
            >
            <v-spacer></v-spacer>
            <v-btn
              v-if="canCreate && allowedToAdd"
              color="primary"
              dark
              class="mb-2"
              @click="adicionar"
              >{{ titleNew }}</v-btn
            >
          </v-toolbar>
        </template>
        <template v-slot:[`item.cnpj`]="{ item }">
          {{ item.cnpj === null ? item.cpf : item.cnpj }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="canUpdate"
                small
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                >mdi-account-supervisor-circle</v-icon
              >
            </template>
            <v-list v-if="item.admins && item.admins.length > 0">
              <v-list-item v-for="admin in item.admins" :key="admin.user.id">
                <v-list-item-title>{{ admin.user.email }}</v-list-item-title>
              </v-list-item>
            </v-list>
            <v-list v-else>
              <v-list-item>Sem contas administrativas</v-list-item>
              <v-list-item @click="abrirDialogoAdicionarConta(item)"
                ><v-btn icon color="green"
                  ><v-icon>mdi-plus</v-icon>
                </v-btn></v-list-item
              >
            </v-list>
          </v-menu>
          <v-icon v-if="canUpdate" small class="mr-2" @click="editItem(item)"
            >mdi-pencil</v-icon
          >
          <v-icon v-if="canDelete" small @click="deleteItem(item)"
            >mdi-delete</v-icon
          >
          <confirm-dialog @confirm="confirmDelete" />
        </template>

        <template v-slot:no-data>
          <v-btn v-if="canList" color="primary" @click="$emit('update')"
            >Reset</v-btn
          >
          <div v-if="!canList">
            <span class="red--text"
              >Você não tem permissão para visualizar as informações desta
              tela</span
            >
          </div>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ConfirmDialog from '@/components/template/ConfirmDialog'
import FormFieldsAdicionarUsuario from '@/components/gerencial/colaboradores/adicionarUsuario/FormFieldsAdicionarUsuario.vue'

export default {
  props: {
    value: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: 'Empresas registradas',
    },
    titleNew: {
      type: String,
      default: 'Nova Empresa',
    },
    allowedToAdd: {
      type: Boolean,
      default: true,
    },
    campoId: {
      type: String,
      required: true,
    },
    tela: {
      type: String,
      required: true,
    },
    tipo: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      formAdicionarAdminEmpresa: false,
      search: '',
      empresaRelacaoAtual: {},
      headers: [
        {
          text: 'Empresa',
          align: 'start',
          value: 'trade_name',
        },
        { text: 'Nome fantasia', value: 'business_name' },
        { text: 'CNPJ', value: 'cnpj' },
        { text: 'Estado', value: 'state' },
        { text: 'Cidade', value: 'city' },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
    }
  },
  components: { ConfirmDialog, FormFieldsAdicionarUsuario },
  computed: {
    ...mapState('auth', ['empresaAtual']),

    canList() {
      return true
      // return this.$canDo(BasicPermissions.VISUALIZAR, this.tela)
    },
    canCreate() {
      return true
      // return this.$canDo(BasicPermissions.CADASTRAR, this.tela)
    },
    canUpdate() {
      return true
      // return this.$canDo(BasicPermissions.EDITAR, this.tela)
    },
    canDelete() {
      return true
      // return this.$canDo(BasicPermissions.EXCLUIR, this.tela)
    },
  },

  methods: {
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),
    ...mapActions('tenants', ['adicionarAdminEmpresa']),

    adicionar() {
      this.$emit('showForm')
    },

    editItem(item) {
      this.$emit('editar', item)
    },

    abrirDialogoAdicionarConta(item) {
      this.formAdicionarAdminEmpresa = true
      this.empresaRelacaoAtual = item
    },
    async adicionarContaAdministrativa({ user, tenantAdminEmpresa, tipo }) {
      try {
        await this.adicionarAdminEmpresa({
          user,
          tenantAdminEmpresa,
          empresa: this.empresaRelacaoAtual[this.campoId],
          grupo: this.empresaRelacaoAtual.adminGroup,
          tipo,
        })
        this.successMessage('Conta administrativa adicionada com sucesso')
        this.$emit('update')
        this.formAdicionarAdminEmpresa = false
      } catch (e) {
        this.errorMessage('Erro ao adicionar conta administrativa')
      }
    },
    deleteItem(item) {
      this.empresaRelacaoAtual = item
      this.setDialogMessage({
        title: 'Confirmar exclusão?',
        message: 'Deseja mesmo excluir essa empresa da sua base?',
      })
    },

    async confirmDelete() {
      this.$emit('delete', this.empresaRelacaoAtual)
    },
  },
}
</script>
