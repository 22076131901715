<template>
  <v-card class="pa-4">
    <v-card-title>Configuração do SubProduto {{ subProduto.descricao }}</v-card-title>
    <v-divider class="my-4" />
    <config-sub-produto-edit :subProduto="subProduto" />
  </v-card>
</template>

<script>
import ConfigSubProdutoEdit from './ConfigSubProdutoEdit.vue'

export default {
  name: 'ProdutosFornecedorConfigSubProduto',
  props: {
    subProduto: {
      type: Object,
      required: true,
    },
  },
  components: {
    ConfigSubProdutoEdit,
  },
}
</script>
