<template>
  <div class="mb-7">
    <v-card-text class="pa-5 border-bottom">
      <h3
        class="subtitle blue-grey--text text--darken-2 font-weight-regular mt-3"
      >
        Informações dos serviços do contrato com o fornecedor
      </h3>
    </v-card-text>
    <v-card-text class="pa-5">
      <v-form @submit.prevent="saveServices">
        <div class="mt-4">
          <v-data-table
            disable-sort
            :headers="headers"
            :items="servicosAutorizados"
            sort-by="descricao"
            ref="table"
            class="border"
            @toggle-select-all="toggleSelectAll"
            selectable-key="selected"
            show-select
          >
            <template v-slot:[`header.data-table-select`]>
              <v-checkbox color="rgba(0, 0, 0, 0.6)" v-model="isAllSelected" />
            </template>
            <template v-slot:[`body`]>
              <draggable
                v-model="servicosAutorizados"
                class="tbody"
                @change="changeOrdemServico"
              >
                <tr
                  v-for="(servico, index) in servicosAutorizados"
                  :key="index"
                >
                  <td>
                    <v-checkbox
                      color="rgba(0, 0, 0, 0.6)"
                      v-model="servico.selected"
                      @click="changeSelected(servico.selected)"
                    />
                  </td>
                  <td>{{ servico.descricao }}</td>
                  <td style="text-transform: capitalize">
                    {{ servico.tipoTarifa }}
                  </td>
                  <td class="valor">
                    <vuetify-money
                      ref="valor"
                      v-model="servico.valor"
                      label="Valor"
                    />
                  </td>
                  <td>
                    <v-checkbox
                      color="rgba(0, 0, 0, 0.6)"
                      v-model="servico.obrigatorio"
                    />
                  </td>
                  <td>
                    <v-icon small class="mr-2" @click="editItem(servico)"
                      >mdi-pencil</v-icon
                    >
                    <v-icon
                      v-if="servico.public_id_autorizado"
                      small
                      class="mr-2"
                      @click="addTarifa(servico)"
                      >mdi-currency-usd</v-icon
                    >
                  </td>
                </tr>
              </draggable>
            </template>
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title>Serviços</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
              </v-toolbar>
            </template>
            <template v-slot:no-data>
              <span>Sem serviços</span>
            </template>
          </v-data-table>
          <div class="d-flex justify-end mt-4">
            <v-btn color="primary" dark type="submit">Salvar</v-btn>
          </div>
        </div>
      </v-form>
    </v-card-text>
    <v-dialog v-model="dialog" max-width="500px">
      <cadastro-servico
        formTitle="Cadastrar Serviço"
        ref="cadastroServico"
        v-model="servicoEdicaoEtapa"
        :value="this.servicoEdicaoEtapa"
        v-if="dialog"
        :fornecedorPublicID="this.fornecedorPublicID"
        @prestadorData="prestadoresList"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import draggable from 'vuedraggable'
import CadastroServico from './etapaFornecedor/CadastroServico.vue'
import PatioApi from '@/services/patio/index'
import GatesApi from '@/services/tenants/gates'

export default {
  props: {
    disabled: Boolean,
    fornecedorPublicID: String,
    value: {
      type: Object,
      required: true,
    },
  },
  components: {
    draggable,
    CadastroServico,
  },
  data() {
    return {
      selected: [],
      isAllSelected: false,
      dialog: false,
      servicosAutorizados: [],
      headers: [
        { text: 'Descrição', value: 'descricao' },
        { text: 'Tipo de tarifa', value: 'tipoTarifa' },
        { text: 'Valor', value: 'valor' },
        { text: 'Obrigatório', value: 'obrigatorio' },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      servicoEdicaoEtapa: {
        id: null,
        descricao: null,
        prestador: null,
        foto: null,
        tipoTarifa: null,
        etapas: [],
      },
      prestadorServicoPublicId: '',
      prestadores: [],
    }
  },
  computed: {
    ...mapState('auth', ['empresaAtual']),
    ...mapState('patio', ['servicos']),

    servicosSelecteds() {
      return this.servicosAutorizados
        .filter((servico) => !!servico.selected)
        .map((servico) => {
          return {
            servico: servico.id,
            obrigatorio: servico.obrigatorio,
          }
        })
    },
  },
  methods: {
    ...mapActions('template', ['successMessage', 'errorMessage']),
    ...mapActions('patio', ['getServicos']),
    addTarifa(item) {
      this.$emit('showTarifa', item)
    },

    editItem(item) {
      this.servicoEdicaoEtapa = { ...item }
      this.dialog = true
    },

    prestadoresList(prestador) {
      this.prestadores.unshift(prestador)
    },

    changeSelected(selected) {
      if (!selected && this.isAllSelected) {
        const servicosSelecteds = this.servicosAutorizados
          .map((servico) => (servico.selected ? servico.id : null))
          .filter((value) => !!value)
        this.isAllSelected = false

        this.$nextTick(() => {
          servicosSelecteds.forEach((id) => {
            const index = this.servicosAutorizados.findIndex(
              (servico) => servico.id === id
            )

            if (index >= 0) {
              this.servicosAutorizados[index].selected = true
            }
          })
        })
      }
    },

    changeOrdemServico() {
      let necessitySaveServices
      this.servicosAutorizados.forEach((servico) => {
        if (!servico.public_id_autorizado && servico.selected) {
          necessitySaveServices = true
        }
      })

      if (necessitySaveServices) {
        this.errorMessage(
          'Não é possível alterar a posição de um serviço autorizado não salvo. Por favor, salve suas alterações antes de alterar a ordem!'
        )
      }

      const ids = this.servicosAutorizados
        .filter((servico) => {
          return !servico.public_id_autorizado
        })
        .map((servico) => servico.id)
      ids.forEach((id) => {
        const index = this.servicosAutorizados
          .map((servico) => servico.id)
          .indexOf(id)
        this.servicosAutorizados.splice(
          this.servicosAutorizados.length - 1,
          0,
          this.servicosAutorizados.splice(index, 1)[0]
        )
        this.servicosAutorizados = this.servicosAutorizados.map(
          (servico, index) => {
            if (servico.public_id_autorizado && servico.selected)
              servico.ordem = index
            return servico
          }
        )
      })
    },

    toggleSelectAll(value) {
      this.servicosAutorizados = this.servicosAutorizados.map((servico) => {
        return {
          ...servico,
          selected: value,
        }
      })
    },

    async saveServices() {
      if (!this.empresaAtual.public_id) return
      const servicosAutorizados = await PatioApi.getServicosAutorizados(
        this.empresaAtual.public_id,
        this.value.public_id,
        {
          deleted: true,
        }
      )

      for await (const [index, servico] of this.servicosAutorizados.entries()) {
        const wasSelected = servicosAutorizados
          .map((servico) => servico.servico.id)
          .indexOf(servico.id)
        if (wasSelected < 0 && servico.selected) {
          let prestadorInfo = this.prestadores.find(
            (p) => p.servico == servico.id
          )
          const data = {
            servico: servico.id,
            fornecedor: this.value.public_id,
            prestador: prestadorInfo ? prestadorInfo.prestador : null,
            obrigatorio: servico.obrigatorio,
            valor: servico.valor,
          }
          const response = await PatioApi.addServicoAutorizado(
            this.empresaAtual.public_id,
            this.value.public_id,
            data
          )
          if (response) {
            this.servicosAutorizados[index] = {
              ...response.servico,
              obrigatorio: response.obrigatorio,
              ordem: response.ordem,
              selected: true,
              valor: response.valor,
              public_id_autorizado: response.public_id,
            }
          }
        } else if (
          wasSelected >= 0 &&
          servico.selected &&
          servico.public_id_autorizado
        ) {
          let prestadorInfo = this.prestadores.find(
            (p) => p.servico == servico.id
          )
          const data = {
            servico: servico.id,
            fornecedor: this.value.public_id,
            prestador: prestadorInfo ? prestadorInfo.prestador : null,
            obrigatorio: servico.obrigatorio,
            valor: servico.valor,
            deleted: null,
          }

          await PatioApi.editServicoAutorizado(
            this.empresaAtual.public_id,
            this.value.public_id,
            servico.public_id_autorizado,
            data
          )

          if (servicosAutorizados[wasSelected].deleted) {
            const index = this.servicosAutorizados
              .map((servico) => servico.id)
              .indexOf(servico.id)

            let novaOrdem = 0
            this.servicosAutorizados.map((servico) => {
              if (servico.ordem && servico.ordem > novaOrdem)
                novaOrdem = servico.ordem + 1
            })
            this.servicosAutorizados[index].ordem = novaOrdem
          }
        } else if (
          wasSelected >= 0 &&
          !servico.selected &&
          servico.public_id_autorizado &&
          !servicosAutorizados[wasSelected].deleted
        ) {
          await PatioApi.deleteServicoAutorizado(
            this.empresaAtual.public_id,
            this.value.public_id,
            servico.public_id_autorizado
          )
          const index = this.servicosAutorizados
            .map((servico) => servico.id)
            .indexOf(servico.id)

          this.servicosAutorizados[index].obrigatorio = false
          this.servicosAutorizados[index].ordem = undefined
        }
      }
      await GatesApi.editarFornecedor(this.empresaAtual, {
        public_id: this.value.public_id,
        servicos_autorizados: this.servicosAutorizados
          .filter((servico) => servico.public_id_autorizado && servico.selected)
          .map((servico) => servico.public_id_autorizado),
      })

      await this.getServicosAutorizados()
    },

    async getServicosAutorizados() {
      if (!this.empresaAtual.public_id) return
      await this.getServicos({ proprietario: this.empresaAtual.public_id })
      let servicos = [...this.servicos]
      const servicosAutorizados = await PatioApi.getServicosAutorizados(
        this.empresaAtual.public_id,
        this.value.public_id,
        {
          deleted: true,
        }
      )

      servicos = servicos
        .map((servico) => {
          const servicoAutorizadoIndex = servicosAutorizados
            .map((servicoAutorizado) => servicoAutorizado.servico.id)
            .indexOf(servico.id)

          if (servicoAutorizadoIndex >= 0) {
            return {
              ...servico,
              valor: servicosAutorizados[servicoAutorizadoIndex].deleted
                ? null
                : servicosAutorizados[servicoAutorizadoIndex].valor,
              obrigatorio: servicosAutorizados[servicoAutorizadoIndex].deleted
                ? false
                : servicosAutorizados[servicoAutorizadoIndex].obrigatorio,
              selected: servicosAutorizados[servicoAutorizadoIndex].deleted
                ? false
                : true,
              ordem: servicosAutorizados[servicoAutorizadoIndex].deleted
                ? undefined
                : servicosAutorizados[servicoAutorizadoIndex].ordem,
              public_id_autorizado:
                servicosAutorizados[servicoAutorizadoIndex].public_id,
            }
          }

          return {
            ...servico,
            valor: null,
            obrigatorio: false,
            selected: false,
            ordem: undefined,
            public_id_autorizado: undefined,
          }
        })
        .sort((a, b) =>
          b.ordem === undefined
            ? -1
            : a.ordem > b.ordem
            ? 1
            : b.ordem > a.ordem
            ? -1
            : 0
        )

      this.servicosAutorizados = [...servicos]
    },
  },

  async created() {
    await this.getServicosAutorizados()
  },

  watch: {
    isAllSelected(val) {
      this.toggleSelectAll(val)
    },
  },
}
</script>

<style lang="scss">
.tbody {
  border-bottom-color: rgb(128, 128, 128);
  border-collapse: separate;
  border-left-color: rgb(128, 128, 128);
  border-right-color: rgb(128, 128, 128);
  border-top-color: rgb(128, 128, 128);
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  display: table-row-group;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  height: 144px;
  line-height: 24px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  overflow-wrap: break-word;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  tab-size: 4;
  text-decoration-thickness: auto;
  text-indent: 0px;
  text-rendering: optimizelegibility;
  text-size-adjust: 100%;
  vertical-align: middle;
  white-space: normal;
  word-break: normal;

  tr {
    border-bottom-color: rgb(128, 128, 128);
    border-collapse: separate;
    border-left-color: rgb(128, 128, 128);
    border-right-color: rgb(128, 128, 128);
    border-top-color: rgb(128, 128, 128);
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.87);
    display: table-row;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    height: 48px;
    line-height: 24px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    overflow-wrap: break-word;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    tab-size: 4;
    text-decoration-thickness: auto;
    text-indent: 0px;
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    vertical-align: middle;
    white-space: normal;
    word-break: normal;

    .valor {
      width: 10%;
    }

    td {
      border-bottom-color: rgba(0, 0, 0, 0.12);
      border-bottom-style: solid;
      border-bottom-width: 1px;
      border-collapse: separate;
      box-sizing: border-box;
      color: rgba(0, 0, 0, 0.87);
      display: table-cell;
      font-family: Poppins, sans-serif;
      font-size: 14px;
      height: 48px;
      line-height: 24px;
      margin-bottom: 0px;
      margin-left: 0px;
      margin-right: 0px;
      margin-top: 0px;
      overflow-wrap: break-word;
      padding-bottom: 0px;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 0px;
      tab-size: 4;
      text-align: start;
      text-decoration-thickness: auto;
      text-indent: 0px;
      text-rendering: optimizelegibility;
      text-size-adjust: 100%;
      transition-delay: 0s;
      transition-duration: 0.2s;
      transition-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
      vertical-align: middle;
      white-space: normal;
      word-break: normal;
    }
  }
}
</style>
