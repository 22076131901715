<template>
  <v-card class="pa-2">
    <v-card-subtitle>Informações do Sub Produto</v-card-subtitle>
    <v-card-text>
      <v-row>
        <v-col cols="12" class="py-0">
          <v-text-field
            label="Descrição"
            name="descricao"
            v-model="forms.descricao"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="py-0">
          <v-checkbox
            v-model="forms.exige_classificacao"
            label="Exige Classificação?"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="py-0">
          <v-checkbox
            v-model="forms.envia_integracao"
            label="Envia Integração?"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="d-flex justify-end">
      <v-btn
        color="secondary"
        @click="save"
        >Salvar</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'ConfigSubProdutoEdit',
  props: {
    subProduto: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      forms: {
        descricao: this.subProduto.descricao,
        exige_classificacao: this.subProduto.exige_classificacao,
        envia_integracao: this.subProduto.envia_integracao,
      },
    }
  },
  methods: {
    ...mapActions('fornecedores', ['editarSubProduto']),
    ...mapActions('template', ['errorMessage', 'successMessage']),

    async save() {
      try {
        const payload = this.forms
        await this.editarSubProduto({
          subProdutoID: this.subProduto.id,
          payload,
        })
        Object.assign(this.subProduto, payload)
        this.successMessage('Sub Produto alterado com sucesso!')
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },
  },
}
</script>
