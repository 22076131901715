<template>
  <v-card class="mb-7">
    <v-card-text class="pa-5 border-bottom">
      <h3
        class="subtitle blue-grey--text text--darken-2 font-weight-regular mt-3"
      >
        Informações do perfil de acesso da empresa
      </h3>
    </v-card-text>
    <v-card-text class="pa-5 border-bottom">
        <v-row>
       <v-col
        cols="12"
        sm="6"
      >
        <v-subheader v-text="'Perfis de acesso da empresa'"></v-subheader>
      </v-col>

      <v-col
        cols="12"
        sm="6"
      >
        <v-select
          v-model="selectedPerfis"
          :items="perfis"
          label="Perfis"
          item-text="nome"
          item-value="public_id"
          :multiple="!unique"
          chips
          hint="Quais os perfis de acesso."
          persistent-hint
        ></v-select>
      </v-col>
    </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>

      <buttons-cadastro @close="close" @save="salvarPerfisEmpresa" />
    </v-card-actions>
  </v-card>
</template>

<script>
import ButtonsCadastro from '@/components/ButtonsCadastro/ButtonsCadastro'

import { mapActions, mapState } from 'vuex'
export default {
  components: { ButtonsCadastro },

  props: {
    disabled: Boolean,
    value: {
      type: Object,
      required: true,
    },
    unique: {
        type: Boolean,
        default: false,
    }
  },
  data() {
    return {
      selectedPerfis: this.value.perfisEmpresa
    }
  },
  async created() {
      await this.getPerfis()
  },
  methods: {
    ...mapActions('tenants', ['atualizarPerfisEmpresa', 'atualizarPerfilRelacionamento']),
    ...mapActions('gerencial', ['getPerfis']),
    ...mapActions('template', ['successMessage', 'errorMessage']),
    async salvarPerfisEmpresa() {
        if (this.unique) {
            try{
                await this.atualizarPerfilRelacionamento({
                    tipo: this.value.tipo,
                    relacionamento: this.value.public_id,
                    perfil: this.selectedPerfis
                })
                this.successMessage('Perfil atualizado com sucesso!')
            } catch(e) {
                this.errorMessage('Erro ao atualizar perfil')
            }
        } else {

            try {
                await this.atualizarPerfisEmpresa({empresa: this.value.public_id, perfis: this.selectedPerfis})
                this.successMessage('Perfis atualizados com sucesso.')
            } catch(e) {
                this.errorMessage('Erro ao atualizar perfis')
            }
        }
    },
    close() {
      this.$emit("close")
    },
  },
  computed: {
    ...mapState('gerencial', ['perfis']),

  },
}
</script>
