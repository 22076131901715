<template>
  <div class="text-center">
    <v-tabs icons-and-text v-model="tab">
      <v-tabs-slider></v-tabs-slider>
      <v-tab href="#dados">
        Dados da empresa <v-icon>mdi-home-city</v-icon></v-tab
      >
      <v-tab href="#contatos"> Contatos <v-icon>mdi-phone</v-icon></v-tab>
      <v-tab href="#produtos"> Produtos <v-icon>mdi-barley</v-icon></v-tab>
      <v-tab href="#embalagem">
        Embalagem <v-icon>mdi-package-variant-closed</v-icon></v-tab
      >
      <!-- <v-tab href="#terminal">
        Terminal <v-icon>mdi-clipboard-edit-outline</v-icon></v-tab
      > -->
      <v-tab href="#servicos"> Serviços <v-icon>mdi-plus-circle</v-icon></v-tab>
      <v-tab href="#perfil">
        Perfil de acesso <v-icon>mdi-file-table-box-multiple</v-icon></v-tab
      >
      <v-tab href="#config"> Configurações <v-icon>mdi-cog</v-icon></v-tab>

      <v-tabs-items v-model="tab">
        <v-tab-item value="dados">
          <dados-empresa v-model="fornecedor" />
        </v-tab-item>
        <v-tab-item value="contatos">
          <dados-contrato v-model="fornecedor" />
        </v-tab-item>
        <v-tab-item value="produtos">
          <produtos-fornecedor v-model="fornecedor" />
        </v-tab-item>
        <v-tab-item value="embalagem">
          <dados-embalagem v-model="fornecedor" />
        </v-tab-item>
        <v-tab-item value="terminal">
          <dados-terminal v-model="fornecedor" />
        </v-tab-item>
        <v-tab-item value="servicos">
          <cadastro-tarifa
            v-if="showFormTarifa"
            :fornecedorPublicID="this.fornecedor.fornecedor_id"
            :servico="servico"
            :servico_autorizado="true"
          ></cadastro-tarifa>
          <dados-servico
            v-else
            :fornecedorPublicID="this.fornecedor.public_id"
            @showTarifa="showTarifa"
            v-model="fornecedor"
          />
          <div class="pa-5 border-top text-right">
            <v-btn
              v-if="showFormTarifa"
              color="black"
              class="text-capitalize"
              dark
              @click="
                servico = {}
                showFormTarifa = false
              "
            >
              Voltar
            </v-btn>
          </div>
        </v-tab-item>
        <v-tab-item value="perfil">
          <dados-perfis v-model="dadosPerfil" @close="$emit('cancelar')" />
        </v-tab-item>
        <v-tab-item value="config">
          <v-card>
            <v-card-title class="d-flex justify-center"> Módulos </v-card-title>
            <v-card-text>
              <v-card-subtitle class="pa-0"> Agendamento </v-card-subtitle>
              <v-row align="center">
                <v-col
                  lg="2"
                  md="2"
                  sm="6"
                  cols="12"
                  class="d-flex align-center"
                >
                  <v-checkbox v-model="config.agendamento.exigePedido" />
                  <span class="mr-4">Exige pedido?</span>
                </v-col>
                <v-col
                  lg="2"
                  md="2"
                  sm="6"
                  cols="12"
                  class="d-flex align-center"
                >
                  <v-checkbox v-model="config.agendamento.exigeRFID" />
                  <span class="mr-4">Exige RFID?</span>
                </v-col>
                <v-col
                  lg="3"
                  md="2"
                  sm="6"
                  cols="12"
                  class="d-flex align-center"
                >
                  <v-checkbox
                    v-model="config.agendamento.exibirClassificacao"
                  />
                  <span class="mr-4"
                    >Exibir classificacao no comprovante de entrada?</span
                  >
                </v-col>
                <v-col
                  lg="3"
                  md="2"
                  sm="6"
                  cols="12"
                  class="d-flex align-center"
                >
                  <v-checkbox v-model="config.agendamento.agendamentoSemCota" />
                  <span class="mr-4">Aceita agendamento sem cota?</span>
                </v-col>
                <v-col
                  lg="2"
                  md="2"
                  sm="6"
                  cols="12"
                  class="d-flex align-center"
                >
                  <v-checkbox v-model="config.agendamento.aceitaAntecipado" />
                  <span class="mr-4">Aceita antecipado?</span>
                </v-col>
                <v-col
                  lg="2"
                  md="2"
                  sm="6"
                  cols="12"
                  class="d-flex align-center"
                >
                  <v-checkbox v-model="config.agendamento.obrigaXML" />
                  <span class="mr-4">Obriga XML no agendamento?</span>
                </v-col>
                <v-col
                  lg="2"
                  md="2"
                  sm="6"
                  cols="12"
                  class="d-flex align-center"
                >
                  <v-checkbox v-model="config.agendamento.cadastroCompleto" />
                  <span class="mr-4">Exige cadastro completo?</span>
                </v-col>
                <v-col
                  lg="2"
                  md="2"
                  sm="6"
                  cols="12"
                  class="d-flex align-center"
                >
                  <v-checkbox v-model="config.agendamento.janelaDeHorario" />
                  <span class="mr-4">Exige Janela de horario?</span>
                </v-col>
                <v-col
                  lg="2"
                  md="2"
                  sm="6"
                  cols="12"
                  class="d-flex align-center"
                >
                  <v-checkbox
                    v-model="config.agendamento.exigeConferenciaAgendamento"
                  />
                  <span class="mr-4">Exige conferencia de agendamento?</span>
                </v-col>
                <v-col
                  lg="2"
                  md="2"
                  sm="6"
                  cols="12"
                  class="d-flex align-center"
                >
                  <v-checkbox v-model="config.agendamento.exigePesagem" />
                  <span class="mr-4">Exige cadastro de Pesagem?</span>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="d-flex justify-end">
              <v-btn color="primary" @click="salvarConfig"> Salvar </v-btn>
            </v-card-actions>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import DadosEmpresa from './DadosEmpresa.vue'
import DadosContrato from './DadosContrato.vue'
import DadosEmbalagem from './DadosEmbalagem.vue'
import ProdutosFornecedor from './ProdutosFornecedor.vue'
import DadosTerminal from './DadosTerminal.vue'
import DadosServico from './DadosServico.vue'
import DadosPerfis from '@/components/gerencial/empresas/editarEmpresa/DadosPerfis.vue'
import CadastroTarifa from '@/components/patio/Servico/Tarifas/CadastroTarifa.vue'
import { mapActions } from 'vuex'
export default {
  props: {
    fornecedor: {
      type: Object,
      required: true,
    },
  },
  components: {
    DadosEmpresa,
    DadosContrato,
    ProdutosFornecedor,
    DadosEmbalagem,
    DadosTerminal,
    DadosServico,
    DadosPerfis,
    CadastroTarifa,
  },
  data() {
    return {
      tab: null,
      servico: {},
      showFormTarifa: false,
      dadosPerfil: {
        perfisEmpresa: this.fornecedor.perfisEmpresa,
        public_id: this.fornecedor.fornecedor_id,
        tipo: 'fornecedores',
      },
      config: {
        agendamento: {
          exigePedido: this.fornecedor.config.agendamento?.exigePedido,
          janelaDeHorario: this.fornecedor.config.agendamento?.janelaDeHorario,
          aceitaAntecipado: this.fornecedor.config.agendamento
            ?.aceitaAntecipado,
          exibirClassificacao: this.fornecedor.config.agendamento
            ?.exibirClassificacao,
          agendamentoSemCota: this.fornecedor.config.agendamento
            ?.agendamentoSemCota,
          exigeRFID: this.fornecedor.config.agendamento?.exigeRFID,
          obrigaXML: this.fornecedor.config.agendamento?.obrigaXML,
          cadastroCompleto: this.fornecedor.config.agendamento
            ?.cadastroCompleto,
          exigeConferenciaAgendamento: this.fornecedor.config.agendamento
            ?.exigeConferenciaAgendamento,
          exigePesagem: this.fornecedor.config.agendamento?.exigePesagem,
        },
      },
    }
  },

  methods: {
    ...mapActions('fornecedores', ['atualizarConfig']),
    ...mapActions('template', ['errorMessage', 'successMessage']),

    showTarifa(item) {
      this.servico = item
      this.showFormTarifa = true
    },

    salvarConfig() {
      try {
        this.atualizarConfig({
          fornecedor: this.fornecedor.public_id,
          config: this.config,
        })
        this.successMessage('Configuração salva com sucesso!')
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },
  },
}
</script>

<style></style>
