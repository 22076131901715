var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('v-data-table',{staticClass:"elevation-1",class:_vm.custom_class,attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":5,"loading":_vm.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.name))]),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.dialogForm = true}}},[_vm._v(" Novo "+_vm._s(_vm.name)+" ")])],1)]},proxy:true},{key:"item.valor",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v("R$ "+_vm._s(item.valor))])]}},{key:"item.valorAdicional",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v("R$ "+_vm._s(item.valorAdicional))])]}},{key:"item.tolerancia",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.tolerancia)+" hora(s)")])]}},{key:"item.limite",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.limite)+" hora(s)")])]}},{key:"item.peso",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(((item.peso) + " (" + (item.tipoPeso) + ")")))])]}},{key:"item.subProduto",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.subProduto.descricao))])]}},{key:"item.embalagem",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.embalagem.descricao))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.prepareDeleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogForm),callback:function ($$v) {_vm.dialogForm=$$v},expression:"dialogForm"}},[_vm._t("form-cadastro",null,{"title":_vm.title,"close":_vm.close})],2),_c('v-dialog',{attrs:{"max-width":"400px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"v-card mb-4"},[_c('span',{staticClass:"headline"},[_vm._v("Deletar "+_vm._s(_vm.name))])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"black darken-1","text":""},on:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v("Cancelar")]),_c('v-btn',{staticStyle:{"background":"#f00","color":"white"},attrs:{"color":"darken-1"},on:{"click":_vm.deleteItem}},[_vm._v("Deletar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }