<template>
  <validation-observer v-slot="{ invalid }">
    <v-card class="pa-2">
      <v-card-title>Código Interno - {{ produto.descricao }}</v-card-title>
      <v-card-text>
        <validation-provider name="codigo interno" rules="required">
          <v-text-field
            label="Código"
            name="codigo interno"
            v-model="codigo_interno"
            hint="Digite aqui o código interno do produto do fornecedor"
            persistent-hint
          />
        </validation-provider>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn
          color="secondary"
          :disabled="invalid"
          @click="$emit('save', { codigo_interno })"
          >Salvar</v-btn
        >
      </v-card-actions>
    </v-card>
  </validation-observer>
</template>

<script>
export default {
  name: 'ProdutosFornecedorCodigoInterno',
  props: {
    produto: { type: Object, required: true },
  },
  data() {
    return {
      codigo_interno: this.produto.codigo_interno,
    }
  },
}
</script>
